import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import Alert from '@mui/material/Alert';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { Client as ConversationsClient } from "@twilio/conversations";
import { Spin } from "antd";
import Loader from "components/Loader";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import * as TwilioChatApi from "../../api/twilioChat.api";
import * as UserApi from "../../api/users.api";
import document from "../../assets/images/document.png";
import geekerLogo from "../../assets/images/newChatLogo.png";
import { SERVER_URL } from "../../constants";
import { useSocket } from "../../context/socketContext";
import { useUser } from "../../context/useContext";
import { formatDateOfTwilioMessage, openNotificationWithIcon } from "../../utils";
import "./style.css";
import UrlifyMessage from "./UrlifyMessage";
// import { useTools } from '../../context/toolContext';

const ChatTextInput = ({
  focusToInput,
  inputRef,
  disableChatButton,
  style,
  keyPress,
  mediaLoader,
}) => {
  useEffect(() => {
    if (focusToInput) inputRef.current.focus();
  });
  return (
    <input
      ref={inputRef}
      type="text"
      placeholder="Type Here...."
      disabled={disableChatButton || mediaLoader}
      style={style}
      onKeyPress={keyPress}
    />


  );
};

const ChatPanelTwilio = ({
  width,
  height,
  job,
  style,
  chatUser,
  chatIdFromProp,
  setRefreshTechUseEffect,
  setdisableDiv,
}) => {
  const { user } = useUser();
  const { socket } = useSocket();
  const chatContainerRef = useRef(null);
  const chatContainerScrollRef = useRef(null);
  const [messages, setMessages] = useState([]);
  // const [textMessage, setTextMessage] = useState('')
  // const {setConversationProxyGlobal} = useTools()
  const fileInputRef = useRef(null);
  const [loadChat, setLoadChat] = useState(false);
  const [participantsList, setParticipantsList] = useState([]);
  const [participantsListDetail, setParticipantsListDetail] = useState([]);
  const [conversationProxy, setConversationProxy] = useState();
  const [chatStatus, setChatStatus] = useState({
    statusString: "",
    status: "",
  });
  const [unseenMessages, setUnseenMessages] = useState([])
  const [copySuccess, setCopySuccess] = useState(false);
  const [refreshSocket, setRefreshSocket] = useState(false);
  const [scrollToChat, setScrollToChat] = useState(false);
  const [prepareFileToSend, setPrepareFileToSend] = useState();
  const [mediaLoader, setMediaLoader] = useState(false);
  const [disableChatButton, setDisableChatButton] = useState(false);
  const [timeStampRefresh, setTimeStampRefresh] = useState(false);
  const [chatServiceSid, setChatServiceSid] = useState();
  const inputRef = useRef(null);
  const [shouldFocusInputRef, setShouldFocusInputRef] = useState(false);
  const [loadMoreChat, setLoadMoreChat] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState();
  const [userChatId, setUserChatId] = useState();
  const [TechData, setTechData] = useState();
  const [finalChatId, setFinalChatId] = useState(chatIdFromProp);
  const [userToken, setUserToken] = useState("");
  const [hoverEnabled, setHoverEnabled] = useState(false);
  const location = useLocation();
  const [sidChat,setSidChat] = useState()
  const queryParams = new URLSearchParams(location.search);
  const tech_id = queryParams.get("message")
    ? queryParams.get("message")
    : false;
  const [chatmessage,setChatmessage] = useState(false);

  // const {stepDeciderForDashboard, setStepDeciderDashboard} = useTools();

  useEffect(()=>{
    // console.log("Debugging online/offline status", stepDeciderForDashboard)
    if(chatmessage){
        socket.emit("refresh-twilio-chat-panel", { job: job.id });
    }
  },[socket,job,chatmessage])


  // This will resetChat Count When Chat Link is clicked on HelpIsOnTheWay Page ~Jagroop
  useEffect(() => {
    if (job?.id) {
      resetChatCount();
    }
  }, [job]);


  

  const markMessagesAsRead = async () => {
    if (conversationProxy) {
      try {
        // Mark all messages as read
        await conversationProxy.setAllMessagesRead();
        ChatParticiantsList(conversationProxy.sid)
      } catch (error) {
        console.error("Error marking messages as read:", error);
      }
    }
  };
  useEffect(() => {


    markMessagesAsRead();
  }, [conversationProxy, messages, refreshSocket]);

 

  useEffect(() => {
    const markMessagesAsRead = async () => {
      if (conversationProxy) {
        try {
          // Example method, ensure this is available in your SDK version
          const lastReadIndex = conversationProxy.lastReadMessageIndex;
        } catch (error) {
          console.error("Error retrieving last read message index:", error);
        }
      }
    };

    markMessagesAsRead();
  }, [conversationProxy, messages, socket]);



  useEffect(() => {
    let timeoutId;
    if (copySuccess) {
      timeoutId = setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [copySuccess]);

  const resetChatCount = () => {
    try {
      let resetCount = window.localStorage.getItem("pendingJobHaveChat");
      if (resetCount) {
        resetCount = JSON.parse(resetCount);
        if (resetCount && resetCount.jobId && resetCount.jobId == job?.id) {
          let dataToSave = {
            jobId: job?.id,
            count: 0,
          };
          window.localStorage.setItem(
            "pendingJobHaveChat",
            JSON.stringify(dataToSave)
          );
        }
      }
      return;
    } catch (error) {
      console.error("error while resetting the chat count", error);
      return;
    }
  };

  useEffect(() => {
    if (finalChatId !== chatIdFromProp) {
      setFinalChatId(chatIdFromProp);
    }
  }, [finalChatId]);

  useEffect(() => {
    (async function () {
      try {
        if (tech_id) {
          let TechUserData = await UserApi.getUserById(tech_id);
          let data = {
            id: TechUserData.id,
            name: `${TechUserData.firstName} ${TechUserData.lastName}`,
          };
          setTechData(data);
        }
      } catch (error) {
        console.error("Error fetching chat details:", error);
      }
    })();
  }, [tech_id]);

  useEffect(() => {
    setConversationProxy();
    setParticipantsList();
    setChatServiceSid();
    setShouldFocusInputRef(false);
    setMessages([]);
  }, [chatIdFromProp]);


  useEffect(() => {
    (async function () {
      const jobDetails = window.sessionStorage.getItem("chatScreen");
      if (jobDetails) {
        window.sessionStorage.removeItem("chatScreen");
      }
      if (job) {
        const data = {
          chatId: job?.id,
          alertAlreadySend: "notSent",
          new_message_alert: false,
        };
        await TwilioChatApi.twilioChatTableUpdate(data);
      }
      try {
        const sessionStorageChatId = window.sessionStorage.getItem("chatId");
        if (sessionStorageChatId) {
          const data = {
            chatId: sessionStorageChatId,
            alertAlreadySend: "notSent",
            new_message_alert: false,
          };
          await TwilioChatApi.twilioChatTableUpdate(data);
        } else {
          const data = {
            chatId: chatIdFromProp,
            alertAlreadySend: "notSent",
            new_message_alert: false,
          };
          await TwilioChatApi.twilioChatTableUpdate(data);
        }
      } catch (error) {
        console.error("Debugging wrong messages intial reset", error);
      }
    })();
  }, [job, chatIdFromProp]);

  useEffect(() => {
    setInterval(() => {
      setTimeStampRefresh((prevValue) => !prevValue);
    }, 60000);
  }, []);

  useEffect(() => {
    fetchChatOrCreate();
  }, [job, chatIdFromProp]);

  // This socket is used to notify another user to refesh twilio chat panel of another side
  useEffect(() => {
    const handleRefresh = async (data) => {
      try {
        if (data?.job === job?.id) {
          await fetchPreviousChat();
          await ChatParticiantsList(sidChat); // Ensure this is awaited properly
          setRefreshSocket(true);
        }
      } catch (error) {
        console.error("Error in handling refresh:", error);
        // Optionally, you can add a user notification or logging system here
      }
    };
  
    socket.on("refresh-twilio-chat-panel-send", handleRefresh);
  
    // Cleanup to avoid memory leaks
    return () => {
      socket.off("refresh-twilio-chat-panel-send", handleRefresh);
    };
  }, [socket, sidChat, messages, job?.id]);
  

  // This will auto-scroll the chat to the current chat portion
  useEffect(() => {
    if (scrollToChat) {
      const chatContainer = chatContainerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
      setScrollToChat(false);
    }
  }, [scrollToChat]);



  useEffect(() => {
    if (conversationProxy) {
      setTimeout(function () {
        setLoadChat(false);
        if (chatIdFromProp) {
          setdisableDiv(false);
        }
        setScrollToChat(true);
      }, 1000);
    }
  }, [conversationProxy]);

  const handleChatScroll = async (e) => {
    if (!loadMoreChat && e.target.scrollTop < 1) {
      setLoadMoreChat(true);
      if (nextPageUrl != null) {
        await fetchPreviousChatOnScroll();
      }
      setLoadMoreChat(false);
    }
  };

  const handleChatScrollButton = async (e) => {
    setLoadMoreChat(true);
    if (nextPageUrl != null) {
      await fetchPreviousChatOnScroll();
    }
    setLoadMoreChat(false);
  };

  const createOrFetchChatId = async (e) => {
    if (tech_id) {
      let customerId = chatUser.id;
      let technicianId = tech_id;
      let chatId = `${customerId}_${technicianId}`;
      setUserChatId(chatId);
      return chatId;
    } else {
      return false;
    }
  };
  // Function Depricated by Jagroop and Karun.
  const getChatIdForUser = async () => {
    let chatId = "";
    if (!tech_id && chatIdFromProp) {
      chatId = chatIdFromProp;
    } else {
      chatId = await createOrFetchChatId();
    }
    return chatId;
  };

  /**
   * @description : This function will firstly try to fetch conversation room if not available then we will create a new conversation
   * @response : Returns chat pannel
   * @author : kartar singh
   */
  const fetchChatOrCreate = async () => {
    try {
      setLoadChat(true);
      if (chatIdFromProp) {
        setdisableDiv(true);
      }
      let chatId = await getChatIdForUser();
      let techdata;
      let techUser = {};
      if (tech_id) {
        let TechUserData = await UserApi.getUserById(tech_id);
        techUser = TechUserData;
        techdata = {
          id: TechUserData.id,
          name: `${TechUserData.firstName} ${TechUserData.lastName}`,
        };
      }

      let twilioData = {
        chatId: chatId ? chatId : job ? job.id : null,
        technician: tech_id ? techdata : null,
        customer:
          user.userType === "customer"
            ? { id: user.id, name: `${user.firstName} ${user.lastName}` }
            : null,
      };
      const responceChat =
        await TwilioChatApi.fetchTwilioConversation(twilioData);
      if (responceChat.twilioData.success) {
        const sid = responceChat?.twilioData?.conversation?.sid;
        const chatServiceSid =
          responceChat?.twilioData?.conversation?.chatServiceSid;
        setChatServiceSid(chatServiceSid);

        if (sid && chatServiceSid) {
          setSidChat(sid)
          const getLatMessage =
            await TwilioChatApi.getTwilioUnreadMessageResponse(sid);
          const addParticipat = await TwilioChatApi.addTwilioParticiants({
            conversationSid: sid,
            userDetails: user,
            chatServiceSid: chatServiceSid,
            id: chatId ? chatId : job ? job.id : null,
          });
          if (tech_id) {
            await TwilioChatApi.addTwilioParticiants({
              conversationSid: sid,
              userDetails: techUser,
              chatServiceSid: chatServiceSid,
              id: chatId ? chatId : job ? job.id : null,
            });
          }
          await fetchPreviousChat();        
          ChatParticiantsList(sid);
          userChatStatus(addParticipat?.twilioData?.token);
          setUserToken(addParticipat?.twilioData?.token);

          if (tech_id) {
            setRefreshTechUseEffect(true);
          }
        }
      }

    } catch (err) {
      console.log("error in fetchChatOrCreate", err);
      setLoadChat(false);
      if (chatIdFromProp) {
        setdisableDiv(false);
      }
      setScrollToChat(false);
    }
  };

  const fetchPreviousChat = async () => {
    try {
      let chatId = await getChatIdForUser();
      let dataToSend = chatId ? { chat_id: chatId } : { chat_id: job?.id };
      const chatResponce = await TwilioChatApi.getTwilioChatDetails(dataToSend);
      
      if (chatResponce.conversation.length > 0) {
        const chatDetails = await TwilioChatApi.getTwilioChat(
          chatResponce?.conversation[0].twilio_chat_service?.sid,
          chatResponce?.conversation[0]?.twilio_chat_service?.chatServiceSid
        );
        if (chatDetails.formattedResponse.length > 0) {
          setMessages(chatDetails.formattedResponse);
          setNextPageUrl(chatDetails.nextPageUrl);
        }
      }
    } catch (error) {
      console.error("Error fetching previous chat:", error);  
    }
  };
 
  const fetchPreviousChatOnScroll = async () => {
    let chatId = await getChatIdForUser();
    const chatResponce = await TwilioChatApi.getTwilioChatDetails(
      chatId ? chatId : job.id
    );
    const chatDetails = await TwilioChatApi.getTwilioChat(
      null,
      chatResponce?.conversation[0].twilio_chat_service?.chatServiceSid,
      nextPageUrl
    );
    if (chatDetails.formattedResponse.length > 0) {
      let previousMessages = [...messages];
      previousMessages.push(...chatDetails.formattedResponse);
      setNextPageUrl(chatDetails.nextPageUrl);
      setMessages(previousMessages);
    }
  };

  // This will fetch participant list of particular conversation group by sid
  const ChatParticiantsList = async (sid) => {
    try {
      const particiantsList = await TwilioChatApi.twilioParticiantsList({
        conversationSid: sid,
      });
      let particiantsAttribute = [];

      particiantsList.twilioData.participants.forEach((particiant) => {
        if (JSON.parse(particiant.attributes).userId !== user.id) {
          particiantsAttribute.push(JSON.parse(particiant.attributes));
        }
  
      });
      // console.log("particiantsList :::::2", particiantsList.twilioData
      //   .participants);
      // setParticipantsListDetail(particiantsList.twilioData
      //   .participants)
      const allParticipants = particiantsList.twilioData.participants
      const userIdentity = user.userType === "customer"
        ? job?.technician?.user?.id
        : job?.customer?.user?.id
      const checkParticipantSeen = getReadIndexDiff(allParticipants)
      setUnseenMessages(checkParticipantSeen)
      setParticipantsList(particiantsAttribute);
    } catch (error) {
      console.log("error while fetching participantlist", error);
      return;
    }
  };
  function getReadIndexDiff(participants) {
    const lastReadIndices = participants.map(participant => participant.lastReadMessageIndex);
    const minIndex = Math.min(...lastReadIndices);
    const maxIndex = Math.max(...lastReadIndices);
  
    const diffIndices = [];
    for (let i = minIndex + 1; i <= maxIndex + 1; i++) {
      diffIndices.push(i);
    }
    return diffIndices;
  }
  
  // This function is used to track the status of connction of conversation and help user to join the conversation
  const userChatStatus = (token) => {
    try {
      const conversationsClient = new ConversationsClient(token);
      conversationsClient.on("connectionStateChanged", (state) => {
        if (state === "connecting")
          setChatStatus({
            statusString: "Connecting to Chat ...",
            status: "default",
          });
        if (state === "connected") {
          setChatStatus({
            statusString: "You are connected.",
            status: "success",
          });
          const warningMessage = {
            senderName: "Geeker",
            text: "Reminder: Geeker's T&C requires that you share all information via internal chat and do not share contact information. If you need any assistance during a call/meeting, please chat with our support team. Good luck with your call!",
            timeStamp: new Date(),
            geekerMsg: true,
          };
          setMessages((prevMessages) => [warningMessage, ...prevMessages]);
        }
        if (state === "denied")
          setChatStatus({
            statusString:
              "Facing issue while connecting your chat. Please refresh the page and try again",
            status: "error",
          });
      });
      // Join the conversation  on the basis of JobId
      conversationsClient.on("conversationJoined", async (conversation) => {
        setChatmessage(true);
        // socket.emit("refresh-twilio-chat-panel", { job: job.id });
        let chatId = await getChatIdForUser();
        const friendlyName = conversation.friendlyName;
        const isFriendlyNameMatchingJobId = friendlyName === job?.id;
        const isFriendlyNameMatchingUserChatId = friendlyName === chatId;
        let checkId = chatId
          ? isFriendlyNameMatchingUserChatId
          : isFriendlyNameMatchingJobId;
        if (checkId) {
          setConversationProxy(conversation);
          if (conversation?.sid) {
            const response = await getLastReadMessageDetails(conversation?.sid);
            let lastReadMessageId =
              response != false
                ? response
                : conversation?._internalState?.lastMessage?.index;
            if (lastReadMessageId != null && lastReadMessageId >= 0) {
              conversation.updateLastReadMessageIndex(lastReadMessageId);
            }
          }
          if (chatId && !chatId.includes("job_")) {
            if (chatId && chatId.includes("Admin_")) {
              const splitString = chatId.split("_");
              const extractedString = splitString.slice(1).join("_");
              

              if (user.userType === "customer") {
                socket.emit("refresh-twilio-unread-messages", {
                  customerUserId: extractedString,
                });
              } else {
                socket.emit("refresh-twilio-unread-messages", {
                  technicianUserId: extractedString,
                });
              }
            } else {
              const splitChatIdFromProp = chatId.split("_");
              socket.emit("refresh-twilio-unread-messages", {
                customerUserId: `${splitChatIdFromProp[0]}_${splitChatIdFromProp[1]}`,
                technicianUserId: `${splitChatIdFromProp[2]}_${splitChatIdFromProp[3]}`,
              });
            }
          }

          // setConversationProxyGlobal(conversation)
          //  conversationProxy.updateLastReadMessageIndex(lastReadMessageIndex);
        }
      });
    } catch (error) {
      setChatStatus({
        statusString:
          "Facing issue while connecting your chat. Please refresh the page and try again",
        status: "error",
      });
    }
  };

  const getFinalChatId = () => {
    let chatId;
    try {
      // if (tech_id) {
      //   let customerId = chatUser.id
      //   let technicianId = tech_id
      //   let chatId = `${customerId}_${technicianId}`
      //   setUserChatId(chatId)
      //   return chatId
      // }
      // Works only in case of Chat regarding a job. Technician, Customers, SuperAdmin Geeker
      if (job?.id) {
        chatId = job?.id;
        return chatId;
      }
      if (window.sessionStorage.getItem("chatId")) {
        chatId = window.sessionStorage.getItem("chatId");
        return chatId;
      }
      if (chatIdFromProp) {
        chatId = chatIdFromProp;
        return chatId;
      }
    } catch (error) {
      console.error(
        "Debugging wrong messages getFinalChatId catch error:",
        error
      );
      return false;
    }
  };

  useEffect(() => {
    (async function () {
      try {
        if (conversationProxy) {
          let updatedMessages = [...messages];
          // let updatedMessages = [];
          conversationProxy.on("messageAdded", async (data) => {
            let lastReadMessageIndex = data?.state?.index;
            let lastReadMessageId = data?.state?.sid;
            let chatId = getFinalChatId();

            const author = data?.state?.author;
            const receiverUser =
              data?.conversation?._entity?.syncDocumentImpl?.descriptor?.data
                ?.lastMessage?.author;
            if (
              receiverUser == author &&
              conversationProxy?.friendlyName &&
              conversationProxy?.friendlyName == chatId
            ) {
              conversationProxy.updateLastReadMessageIndex(
                lastReadMessageIndex
              );
            }
            if (author == user?.id) {
              // console.log(
              //   "Debugging wrong messages updateLastReadMessageIndex",
              //   { author, userID: user?.id, lastReadMessageIndex }
              // );
              conversationProxy.updateLastReadMessageIndex(
                lastReadMessageIndex
              );
            }
            // If we get response of getFinalChatId as false then we will use chatId  from conversationProxy
            if (!chatId) {
              console.error("IF YOU SEE THIS ERROR REPORT TO JAGROOP::", {
                chatId,
                currentChatId: conversationProxy.friendlyName,
              });
              chatId = conversationProxy.friendlyName;
            }
            let condition = conversationProxy?.friendlyName;
            // is_author_exists_in_selected_chat

            let checkCondition = condition === chatId || condition === job?.id;
            // console.log(
            //   "Debugging wrong messages checkCondition::",
            //   { chatId, condition, job, userId: user.id },
            //   condition === chatIdFromProp,
            //   condition === chatId,
            //   condition === job?.id
            // );

            if (checkCondition) {
              // let updatedMessages = [];
              console.log("Debugging wrong messages updatedMessages", {
                updatedMessages,
              });

              let imageUrl =
                data.state.body === "file has been uploaded" && data.state.media
                  ? await data.state.media.getContentTemporaryUrl()
                  : false;
              let mediaDetails =
                data.state.body === "file has been uploaded" && data.state.media
                  ? {
                    chatServiceSid: chatServiceSid,
                    mediaSid: data.state.media?.state?.sid,
                  }
                  : {};
              let content_type =
                data.state.body === "file has been uploaded" && data.state.media
                  ? data.state.media.contentType
                  : false;
              updatedMessages = [
                {
                  senderName: data.state.attributes.userName,
                  text: data.state.body,
                  author: data.state.author,
                  timeStamp: data.state.attributes.timeStamp,
                  imageUrl: imageUrl,
                  content_type: content_type,
                  mediaDetails: mediaDetails,
                  index: data?.state?.index
                },
                ...updatedMessages,
              ];
              setMediaLoader(false);
              console.log('updatedMessages ::::', updatedMessages)
              setMessages(updatedMessages);

              if (lastReadMessageIndex > 5 && conversationProxy?.sid) {
                console.log("Debugging wrong messages nextPageUrl", {
                  lastReadMessageIndex,
                  sid: conversationProxy?.sid,
                });
                const url = `https://conversations.twilio.com/v1/Conversations/${conversationProxy?.sid}/Messages?Order=desc&PageSize=5&Page=1&PageToken=PT15`;
                setNextPageUrl(url);
              }
              // if(stepDeciderForDashboard === 15){
              //   conversationProxy.updateLastReadMessageIndex(lastReadMessageIndex)
              // }
            }
          });

          // conversationProxy.on("isOnline", async () => {
          //   console.log("Participant is online")
          // })
          setRefreshSocket(false);
        }
      } catch (error) {
        console.error("Error Twillio messageAdded:", error);
      }
    })();
  }, [conversationProxy]);

  // }, [conversationProxy, refreshSocket, timeStampRefresh])

  const getLastReadMessageDetails = async (chatId) => {
    try {
      const response = await TwilioChatApi.lastMessageSenderDetails(chatId);
      if (response && response?.index) {
        // console.log(
        //   "Debugging wrong messages getLastReadMessageDetails initial",
        //   response?.index
        // );
        return response?.index;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (conversationProxy) {
      // conversationProxy.on("messageAdded", async(data) => {
      //    const object = {
      //      phoneNumber :'98762869405', sender : "Technician", receiver :"Jagroop", receiverEmail : 'jagroop@yopmail.com', userType : "customer"
      //    }
      //   //  await TwilioChatApi.sendTwilioChatAlerts()
      // })

      (async function () {
        const response = await conversationProxy.getUnreadMessagesCount();
        // console.log("getUnreadMessagesCount", response);
      })();
    }
  }, [conversationProxy]);

  const handleSendMessage = async () => {
    let chatId = await getChatIdForUser();

    let textMessage = inputRef.current.value;

    if (textMessage.length > 1000) {
      openNotificationWithIcon("error", "Error", "Characters limit exceeds !");
      return;
    }
  console.log("textMessage",textMessage)
    if (
      conversationProxy &&
      textMessage.trim().length > 0 &&
      !prepareFileToSend
    ) {
      setDisableChatButton(true);

      const attribues = {
        timeStamp: new Date(),
        userName: user?.firstName + " " + user?.lastName,
        email: user?.email,
        userType: user?.userType,
      };

      conversationProxy.sendMessage(textMessage, attribues).then((message) => {
        inputRef.current.value = "";
      });

      if (job) {

        if (job && job?.status == 'long-job') {


          const recipientData = {
            jobId: job?.id,
            senderType: user?.userType, // "customer" or "technician"
            senderId: user?.id,
            recipientId:
              user.userType === "customer"
                ? job?.technician?.user?.id
                : job?.customer?.user?.id,
          };

          socket.emit("check-recipient-in-chat", recipientData);

        }
        let socketData = {
          jobId: job?.id,
          customerId: job?.customer ? job.customer.id : null,
          technicianId: job?.technician
            ? job?.technician?.id
            : job?.post_again_reference_technician
              ? job?.post_again_reference_technician
              : null,
        };

        if (
          user.userType === "customer" &&
          job.customer.id === user.customer.id &&
          !job.keepSearching
        ) {
          
          socket.emit("talk-js-notification", socketData);
          socket.emit("user-twilioChat-notification", {
            chatId: job.id,
            userType: user.userType,
          });
        } else {
          if (
            ((job?.technician?.id === user?.technician?.id) ||
              (job?.post_again_reference_technician === user?.technician?.user)) &&
            !job.keepSearching
          ) {

            socket.emit("talk-js-notification-to-customer", socketData);
          }
        }

        if (user.userType === "customer" && job.keepSearching) {
          socketData.keepsearching = true;

          socket.emit("talk-js-notification", socketData);
          socket.emit("user-twilioChat-notification", {
            chatId: job.id,
            userType: user.userType,
          });
        } else if (job.keepSearching) {
          socketData.keepsearching = true;

          socket.emit("talk-js-notification-to-customer", socketData);
        }
        setTimeout(() => {
          socket.emit("refresh-twilio-chat-panel", { job: job.id });
        }, 2000);
      }

      let finalChatId = getFinalChatId();


      if (finalChatId) {
        const splitChatIdFromProp = finalChatId.split("_");


        socket.emit("user-twilioChat-notification", {
          chatId: finalChatId,
          userType: user.userType,
        });
        socket.emit("user-twilioChat-notification-admin", {
          chatId: finalChatId,
          userType: user.userType,
        });

        if (finalChatId && !finalChatId.includes("job_")) {
          if (finalChatId.includes("Admin_")) {
            const splitString = finalChatId.split("_");
            const extractedString = splitString.slice(1).join("_");


            if (user.userType === "customer") {
              socket.emit("refresh-twilio-unread-messages", {
                customerUserId: extractedString,
              });
            } else {
              socket.emit("refresh-twilio-unread-messages", {
                technicianUserId: extractedString,
              });
            }
          } else {
            socket.emit("refresh-twilio-unread-messages", {
              customerUserId: `${splitChatIdFromProp[0]}_${splitChatIdFromProp[1]}`,
              technicianUserId: `${splitChatIdFromProp[2]}_${splitChatIdFromProp[3]}`,
            });
          }
        }
        setTimeout(() => {
          socket.emit("refresh-twilio-chat-panel", finalChatId);
        }, 2000);
      }

      setScrollToChat(true);
      setTimeout(() => {
        console.log("Re-enabling chat button");
        setDisableChatButton(false);
      }, 500);
    } else if (conversationProxy && prepareFileToSend) {
      console.log("Sending media file...");
      setMediaLoader(true);

      const attribues = {
        timeStamp: new Date(),
        userName: user?.firstName + " " + user?.lastName,
        email: user?.email,
        userType: user?.userType,
      };


      await conversationProxy
        .prepareMessage()
        .setBody("file has been uploaded")
        .addMedia(prepareFileToSend)
        .setAttributes(attribues)
        .build()
        .send();

      inputRef.current.value = "";
      setPrepareFileToSend(null);
      fileInputRef.current.value = null;
    }

    setShouldFocusInputRef(true);

  };


  const containerStyle = {
    width: width,
    minHeight: height,
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    boxShadow: "1px 1px 10px 1px rgb(136, 136, 136)",
  };

  const handleFileInputChange = async (event) => {
    setScrollToChat(true);
    const selectedFile = event.target.files[0];

    if (selectedFile.size > 15 * 1024 * 1024) {
      // File size exceeds 1MB
      openNotificationWithIcon("error", "Error", "File size exceeds 15MB!");
      return;
    }
    var formdata = new FormData();
    formdata.append("file", selectedFile);
    setPrepareFileToSend(formdata);
    inputRef.current.value = selectedFile.name;
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const copyToClipboard = async (messageTOCopy) => {
    try {
      
      const linkRegex = /(https?:\/\/[^\s]+)/g; // Regular expression to match URLs
      const linkMatch = messageTOCopy.match(linkRegex);
      if (linkMatch && linkMatch.length > 0) {
        const linkToCopy = linkMatch[0]; // Assuming the first link found is the one to copy
        await navigator.clipboard.writeText(linkToCopy);
        setCopySuccess(true);
      } else {
        setCopySuccess(false); // No link found to copy
      }
    } catch (err) {
      console.error('Unable to copy text to clipboard:', err);
      setCopySuccess(false);
    }
  };

  if (loadChat) {
    return (
      <div
        className="loader-name-style"
        style={{ ...containerStyle, ...style }}
      >
        <h5 className="d-flex flex-column mb-3">
          {chatStatus.statusString ? chatStatus.statusString : "Loading Chat"}
        </h5>
        <Spin style={{ fontSize: "20px" }} />
      </div>
    );
  }
  return (
    <div className="d-flex flex-column" style={{ ...containerStyle, ...style }}>
      <div className="chat-header d-flex align-items-center pl-3">
        <img
          src={geekerLogo}
          className="geek-icon-style"
          alt="bellIcon"
        />
        <div className="d-flex flex-column">
          <div className="d-flex row names-style">
            {participantsList?.length > 0 &&
              participantsList.map((item, index) => {
                return item.userType && item.userType != "SuperAdmin" ? (
                  <span key={index}>
                    <b>
                      {item.name}
                      {index !== participantsList.length - 1
                        ? `${" "} \u00A0`
                        : " "}
                    </b>
                  </span>
                ) : (
                  ""
                );
              })}
          </div>
          <span style={{ fontSize: "13px" }}>{job?.software?.name}</span>
        </div>
      </div>
      {copySuccess &&
        <Alert severity="success">link has been copied.</Alert>
      }

      <div
        id="scrollToChat"
        onScroll={handleChatScroll}
        ref={chatContainerRef}
        className="chat-display-box chat-container justify-content-end"
        style={{ height: `calc(${height} - 110px)`, overflowY: "scroll" }}
      >
        {loadMoreChat && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {nextPageUrl && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleChatScrollButton}>load more</Button>
          </Box>
        )}
        <div
          className="d-flex flex-column-reverse justify-content-start"
          style={{ height: "auto", minHeight: "100%" }}
        >
          {mediaLoader && (
            <div
              className="d-flex align-self-end "
              style={{ marginRight: "20px" }}
            >
              <Loader height="50%" />
            </div>
          )}
          {messages.map((message, index) =>
            message.author === user.id ? (
              <div
                key={index}
                className="message-div d-flex flex-column align-self-end align-items-end"
              >
                <div
                  className="row"
                  style={{ margin: 0, display: "flex", justifyContent: "end" }}
                >
                  <h6 className="chat-customer-name">{message?.senderName}</h6>
                </div>
                {message.imageUrl ? (
                  <a
                    href={`${SERVER_URL}/api/public/get-media-link-updated?chatServiceSid=${message?.mediaDetails?.chatServiceSid}&mediaSid=${message?.mediaDetails?.mediaSid}`}
                    target="_blank"
                  >
                    {message.imageUrl &&
                      message.content_type.includes("image/") ? (
                      <img
                        src={message.imageUrl}
                        style={{ maxHeight: "160px" }}
                      />
                    ) : (
                      <img src={document} style={{ maxHeight: "80px" }} />
                    )}
                    <p className="media-name-style">{message.mediaName}</p>
                  </a>
                ) : (
                  <div
                    className={`me-chat-div`}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    <UrlifyMessage
                      message={message.text}
                      copyToClipboard={copyToClipboard}
                      formatDateOfTwilioMessage={formatDateOfTwilioMessage}
                      timeStamp={message.timeStamp}
                      geekerMsg={message.geekerMsg ? message.geekerMsg : false}
                      index={message.index}
                      unseenMessages={unseenMessages}
                      mychat={true}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div key={index} className="message-div d-flex flex-column">
                <h6 className="chat-customer-name">{message?.senderName}</h6>
                {message.imageUrl ? (
                  <a
                    href={`${SERVER_URL}/api/public/get-media-link-updated?chatServiceSid=${message?.mediaDetails?.chatServiceSid}&mediaSid=${message?.mediaDetails?.mediaSid}`}
                    target="_blank"
                  >
                    {message.imageUrl &&
                      message.content_type.includes("image/") ? (
                      <img
                        src={message.imageUrl}
                        style={{ maxHeight: "160px" }}
                      />
                    ) : (
                      <img src={document} style={{ maxHeight: "80px" }} />
                    )}
                    <p className="media-name-style">{message.mediaName}</p>
                  </a>
                ) : (
                  <div
                    className="me-chat-div-reverse"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    <UrlifyMessage
                      message={message.text}
                      copyToClipboard={copyToClipboard}
                      formatDateOfTwilioMessage={formatDateOfTwilioMessage}
                      timeStamp={message.timeStamp}
                      geekerMsg={message.geekerMsg ? message.geekerMsg : false}
                      mychat={false}

                    />
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>

      <div className="chat-input-box d-flex align-items-center">
        <ChatTextInput
          inputRef={inputRef}
          mediaLoader={mediaLoader}
          disableChatButton={disableChatButton}
          focusToInput={shouldFocusInputRef}
          style={{
            width: "90%",
            height: "100%",
            padding: "10px",
            border: "none",
            outline: "none",
          }}
          keyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />

        <input
          type="file"
          id="attach-file"
          disabled={mediaLoader}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          ref={fileInputRef}
        />
        <IconButton
          aria-label="attach-file"
          component="span"
          onClick={handleIconClick}
          disabled={mediaLoader}
          style={{ transform: "rotate(45deg)", marginRight: "10px" }}
        >
          <AttachFileIcon />
        </IconButton>

        <IconButton
          style={{
            backgroundColor: "#1bd4d5",
            color: "white",
            marginRight: "15px",
          }}
          onClick={handleSendMessage}
          disabled={disableChatButton || mediaLoader}
        >
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default React.memo(ChatPanelTwilio);
