import React, { useState, useEffect } from 'react';
import { Upload, Button, Progress,Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as JobFileApi from "../../../api/jobFile.api";
import 'antd/dist/antd.css';
import { openNotificationWithIcon } from 'utils';
import { meetingSocket } from '../../../context/authContext'
import mixpanel from 'mixpanel-browser';
import {
  FILE_SIZE_MSG,
} from "../../../constants";

const FileUploadForm = ({ user, job, setFiles, socket }) => {
  const [fileList, setFileList] = useState([]);
  const [progressCheck, setProgressCheck] = useState(0);
  const allowedFileTypes = [".doc", ".xls", ".xlsx", ".csv", ".pdf", ".jpg", ".jpeg", ".png", ".xlsm"];
  const [checkStatus, setCheckStatus] = useState(false);
  const [uploading, setUploading] = useState(false);
 
  // const handleUpload = async () => {
  //   try {
     
  //     if (fileList.length === 0) {
  //       openNotificationWithIcon('error', 'Error', 'Please select a file to upload');
  //       return;
  //     }
     
  //     setUploading(true);
  //     let formData = new FormData();
  //     fileList.forEach((file) => {
  //       formData.append('files', file.originFileObj);
  //     });

  //     formData.append('user', user.id);
  //     formData.append('job', job.id);
  //     formData.append('uploadedBy', user.userType == 'customer' ? 'customer' : 'technician');
  //     setProgressCheck(0);
  //     let response = await JobFileApi.uploadFile(formData)
  //     if (response.status) {
  //       setProgressCheck(30)
  //       setFileList([])
  //       let jobIds = [job.id];
  //       if (job && job.is_transferred && job.transfer_reference_job) {
  //         jobIds.push(job.transfer_reference_job);
  //       }
  //       meetingSocket.emit('upload-file-from-frontend', { jobId: job.id, job:job,userType: user.userType })
       
  //       const updatedFiles = await JobFileApi.listOfUploadFile({ jobIds: jobIds });
  //       setProgressCheck(70)
  //       setFiles(updatedFiles.files);
  //       socket.emit('uploadFile-notification', { job: job, userType: user.userType});
  //       openNotificationWithIcon('success', 'Success', "File uploaded successfully");
  //       mixpanel.identify(user.email);
  //       mixpanel.track(
  //         "File uploaded successfully",
  //         {
  //           JobId: job.id,
  //           userId: user.id,
  //           userType: user.userType
  //         }
  //       );
        
  //       setProgressCheck(100);
  //       setTimeout(() => {
  //         setCheckStatus(false);
  //         setProgressCheck(0)
  //         setUploading(false);
  //       }, 2000);
       
       
  //     }
  //     else {
  //       // Handle failure case and show detailed error message
  //       const errorMessage = response.errorDetails ? `Error: ${response.errorDetails.message}` : 'Unauthorized access to upload file';
  //       openNotificationWithIcon('error', 'Error', errorMessage);
  //       // openNotificationWithIcon('error', 'Error', 'Unauthorized access to upload file');
  //       setUploading(false);
  //     }
  //   } catch (error) {
  //     console.error('Error uploading files:', error);
  //     setUploading(false);
  //     openNotificationWithIcon('error', 'Error', 'File upload functionality has an issue, please try again after some time');
  //   }
  // };

  const handleUpload = async () => {
    try {
      if (fileList.length === 0) {
        openNotificationWithIcon('error', 'Error', 'Please select a file to upload');
        return;
      }
  
      setUploading(true);
      let formData = new FormData();
      fileList.forEach((file) => {
        formData.append('files', file.originFileObj);
      });
  
      formData.append('user', user.id);
      formData.append('job', job.id);
      formData.append('uploadedBy', user.userType === 'customer' ? 'customer' : 'technician');
      setProgressCheck(0);
  
      // Optimistically add file to state
      const newFile = {
        fileName: fileList[0].name, // You can update this with the appropriate file name
        fileUrl: URL.createObjectURL(fileList[0].originFileObj), // Use the object URL for the file
        jobFileData: {
          status: 'active',
          job: job.id,
          uploadedBy: user.userType,
          fileName: fileList[0].name,
          fileUrl: URL.createObjectURL(fileList[0].originFileObj),
        }
      };
  
      // Add the newly uploaded file optimistically
      setFiles(prevFiles => [newFile, ...prevFiles]);
  
      // Proceed with uploading the file to the server
      let response = await JobFileApi.uploadFile(formData);
      if (response.status) {
        setProgressCheck(30);
        setFileList([]);
        let jobIds = [job.id];
        if (job && job.is_transferred && job.transfer_reference_job) {
          jobIds.push(job.transfer_reference_job);
        }
        meetingSocket.emit('upload-file-from-frontend', { jobId: job.id, job: job, userType: user.userType });
  
        // Now get the updated list of files from the backend
        const updatedFiles = await JobFileApi.listOfUploadFile({ jobIds: jobIds });
        setProgressCheck(70);
        setFiles(updatedFiles.files);
        socket.emit('uploadFile-notification', { job: job, userType: user.userType });
        openNotificationWithIcon('success', 'Success', "File uploaded successfully");
        
        mixpanel.identify(user.email);
        mixpanel.track("File uploaded successfully", {
          JobId: job.id,
          userId: user.id,
          userType: user.userType
        });
  
        setProgressCheck(100);
        setTimeout(() => {
          setCheckStatus(false);
          setProgressCheck(0);
          setUploading(false);
        }, 2000);
      } else {
        const errorMessage = response.errorDetails ? `Error: ${response.errorDetails.message}` : 'Unauthorized access to upload file';
        openNotificationWithIcon('error', 'Error', errorMessage);
        setUploading(false);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploading(false);
      openNotificationWithIcon('error', 'Error', 'File upload functionality has an issue, please try again after some time');
    }
  };
  
  const handleFileListChange = ({ fileList: newFileList }) => {
    const fileSizeExceedsLimit = newFileList.some(file => file.size > 100 * 1024 * 1024); // Check if any file size exceeds 100MB
    if (fileSizeExceedsLimit) {
        openNotificationWithIcon('error', 'Error', FILE_SIZE_MSG);
        setCheckStatus(false);
        return false;
    }
    const invalidFiles = newFileList.filter(file => !allowedFileTypes.includes(file.name.slice(file.name.lastIndexOf('.'))));
    if (invalidFiles.length > 0) {
      // Display a message informing the user about the invalid files
      alert(`Invalid file(s) selected: ${invalidFiles.map(file => file.name).join(', ')}. Please select only the allowed file types: ${allowedFileTypes.join(', ')}`);
      return;
    }
    newFileList.length > 0 ? setCheckStatus(true) : setCheckStatus(false);
    setFileList(newFileList);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          <Upload
            fileList={fileList}
            onChange={handleFileListChange}
            multiple
            beforeUpload={() => false}
            listType="text" // Set listType to "text" for a simpler file list layout
            className="custom-upload"
          >
            <Button block className="w-100" style={{ backgroundColor: '#2db7f5', borderColor: '#2db7f5', color: '#fff', fontWeight: 600 }}>
              <UploadOutlined style={{ color: '#fff', marginRight: '8px' }} /> Select Files
            </Button>
          </Upload>
          <div className="my-3"></div>
          <Button block onClick={handleUpload} style={{ backgroundColor: '#4EE1E2', color: '#fff', fontWeight: 600, width: '50%', height: "50px", borderRadius: '10px', whiteSpace: 'normal' }} disabled={uploading}>
          {uploading ? (
                <Spin size="small" />
              ) : (
                <span>Click Here to Upload</span>
              )}
            </Button>
            {checkStatus &&
            <Progress percent={progressCheck} />
            }
        </div>
      </div>
    </div>
  );
};

export default FileUploadForm;
