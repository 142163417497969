import React, { useState, useRef, useEffect, useMemo } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const UrlifyMessage = ({
    message,
    copyToClipboard,
    formatDateOfTwilioMessage,
    timeStamp,
    geekerMsg,
    index,
    unseenMessages,
    mychat
}) => {
    try {
        // Check if message is valid
        if (typeof message !== 'string') {
            throw new Error("Message is not a valid string");
        }

        const urlRegex = /(https?:\/\/[^\s]+)/g;

        const segments = message.split(urlRegex);

        const transformedText = segments.map((segment, index) => {
            if (segment.match(urlRegex)) {
                const url = segment;
                return (
                    <div className="url-container" key={index}>
                        <a style={{ color: '#2196f3' }} href={url} title={url}>
                            <span>{url}</span>
                        </a>
                        <div className="custom-buttons text-center">
                            <div className="custom-buttons-inner">
                                <span
                                    className="button-for-link copy-to-clipboard"
                                    onClick={() => copyToClipboard(url)}
                                    title="Copy to clipboard"
                                >
                                    Copy <ContentCopyIcon />
                                </span>
                                <span
                                    className="button-for-link open-in-new-tab"
                                    onClick={() => window.open(url, '_blank')}
                                    title="Open link in new tab"
                                >
                                    Open <OpenInNewIcon />
                                </span>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <span key={index} style={{ wordBreak: 'break-word' }}>
                        {geekerMsg ? (<span style={{ color: '#007d81' }}>{segment}</span>) : (<span>{segment}</span>)}
                    </span>
                );
            }
        });

        const seenStatus = (() => {
            if (!Array.isArray(unseenMessages)) {
                return null;
            }
            if (unseenMessages.length === 0) {
                return true;
            }
            return !unseenMessages.includes(index);
        })();

        return (

            <>
                {transformedText}
                <span className="chat-time-style">
                    {timeStamp && typeof formatDateOfTwilioMessage === 'function' ?
                        formatDateOfTwilioMessage(timeStamp) : "Invalid date format"
                    }
                    {mychat && !geekerMsg && seenStatus && (
                    <span className="seen-status">
                         
                        <DoneAllIcon
                            style={{
                                color: "#0A84FF",
                                fontSize: "17px",
                            }}
                        />
                    </span>
                    )}
                    {mychat && !geekerMsg && !seenStatus && (
                    <span className="seen-status">
                         
                        <DoneAllIcon
                            style={{
                                color: "inherit",
                                fontSize: "17px",
                            }}
                        />
                    </span>
                    )}
                </span>

            </>
        );
    } catch (error) {
        // Error handling: Render an error message if something goes wrong
        console.error("Error rendering UrlifyMessage component:", error.message);
        return (
            <div style={{ color: 'red' }}>
                Error: {error.message}
            </div>
        );
    }
};

export default UrlifyMessage;
