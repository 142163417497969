import { JOBS_COUNT_LIMIT } from "constants/expandableJobContants";
import moment from "moment";
import { haveUnreadMessagesForPendingJob } from "utils";
import * as JobApi from "../api/job.api";
export const profileIdReturner = (user) => {
  let id = false;
  if (user && user?.userType == "customer") {
    id = user?.customer?.id;
  }
  if (user && user?.userType == "technician") {
    id = user?.technician?.id;
  }
  return id;
};

export const dateFormatter = (date) => {
  if (new Date(date)) {
    const formattedDate = moment(date).format("ddd, MMM D, YYYY, hh:mm A");
    return formattedDate;
  } else {
    return false;
  }
};

export const dashboardJobsData = async (
  user,
  initialRender,
  userType,
  tab,
  pageNumber
) => {
  const profileId = profileIdReturner(user);
  const userId = user?.id
  const dataObj = dataObjectReturner(initialRender, tab, userType, pageNumber,userId,user);

  const apiResponse = await JobApi.getDashboardJobsList(profileId, dataObj, userId);
  if (apiResponse && apiResponse.success) {
    const result = dataExtractor(apiResponse);
    return result;
  }
};

const dataObjectReturner = (initialRender, tab, userType, pageNumber,userId,user) => {
  let dataObj = {};
  dataObj["tab"] = tab;
  dataObj["pageNumber"] = pageNumber;
  dataObj["limit"] = JOBS_COUNT_LIMIT;
  dataObj["userType"] = userType;
  dataObj["initialRender"] = initialRender;
  dataObj["userId"] = userId;
  dataObj["userIsLive"] = userType == "customer" ? user?.customer?.customerType : user?.technician?.technicianType

  return dataObj;
};

const dataExtractor = (apiResponse) => {
  const tab = apiResponse.tab;
  const totalJobsPerTab = apiResponse.totalJobsPerTab
  if (tab == "live-jobs-tab") {
    const data = apiResponse?.activeTab?.data;
    const totalJobs = apiResponse?.activeTab?.totalJobs;
    return { tab: tab, tabData: data, totalJobs: totalJobs , totalJobsPerTab:totalJobsPerTab };
  } else {
    const data = apiResponse?.completedTab?.data;
    const totalJobs = apiResponse?.completedTab?.totalJobs;
    return { tab: tab, tabData: data, totalJobs: totalJobs, totalJobsPerTab:totalJobsPerTab };
  }
};

export const paginationFinder = (totalJobs) => {
  if (totalJobs == 0) {
    return 1;
  } else {
    let totalPages = totalJobs / JOBS_COUNT_LIMIT;
    return Math.ceil(totalPages);
  }
};

export const showChatIconOrNot = (job, user, jobIdFromMessage) => {

  const isCustomer = user.userType === "customer";
  const isTechnician = user.userType === "technician";

  const isScheduled = job.status === "Scheduled";
  const isDeclined = job.status === "Declined";
  const isPending = job.status === "Pending";
  const isWaiting = job.status === "Waiting";
  const islongJob = job.status === "long-job";
  const isTechDeclined =
    job?.tech_declined_ids &&
    Array.isArray(job.tech_declined_ids) &&
    job.tech_declined_ids.length > 0;
  const isJobAccepted = job?.status === "Accepted";
  const isScheduledAccepted = job.schedule_accepted;
  const isInProgress = job.status === "Inprogress";
  const isCompleted = job.status === "Completed";
  const isDraft = job.status === "Draft";

  const customerDeclineTheJob = job?.declinedByCustomer?.includes(
    user?.technician?.id
  );
  const hasUnreadMessages = haveUnreadMessagesForPendingJob(job.id);
  const isWithin24Hours =
    job.meeting_end_time &&
    (job.status === "Completed" || job.status === "long-job") &&
    diff_hours(job.meeting_end_time) <= 24;
  const isPostAgainWithSameTech = job?.post_again_reference_technician;
  const isJobIdMatch = jobIdFromMessage.includes(job.id);

  const shouldRenderIconButtonOnSocket =
    (((isScheduled || isPending || isWaiting || isCompleted || islongJob) && isCustomer)) ||
    (((isJobAccepted || isInProgress || isCompleted || islongJob) ||
      isPostAgainWithSameTech ||
      (isScheduled && isScheduledAccepted)) && isJobIdMatch);

  const shouldRenderIconButtonWithRedDot =
    job.id &&
    job.tech_message_dashbord &&
    (((isScheduled || islongJob ||
      isPending ||
      isWaiting ||
      (isWithin24Hours && isCompleted)) &&
      isCustomer || isTechnician) ||
      isInProgress ||
      isJobAccepted ||
      isPostAgainWithSameTech ||
      // isDeclined ||
      (isScheduled && isScheduledAccepted) ||
      (isWithin24Hours &&
        isCompleted &&
        !isTechDeclined &&
        !customerDeclineTheJob));
  const shouldRenderIconButton =
    job.id &&
    (((isScheduled || isPending || isWaiting || isJobAccepted || islongJob) && isCustomer) ||
      isInProgress ||
      // isJobAccepted ||
      // isDeclined ||
      (isJobAccepted && !isTechDeclined)||
      isCompleted|| islongJob ||
      (isPostAgainWithSameTech && !isDraft && !isDeclined && !isTechDeclined) ||
      (isScheduled && isScheduledAccepted && !isTechDeclined) ||
      (isCompleted && isWithin24Hours && hasUnreadMessages));

  return {
    shouldRenderIconButtonOnSocket,
    shouldRenderIconButtonWithRedDot,
    shouldRenderIconButton,
  };
};

function diff_hours(date) {
  var diff = (new Date().getTime() - new Date(date).getTime(date)) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
}



export const btnListToShowForTech = (job,user) => {

  let showJobDetailsBtn  = true;
  let scheduleJobApplyBtn = false;
  let scheduleJobDeclineBtn = false;
  let cancelScheduleJobTechBtn = false;
  let joinJobBtn = false;
  let acceptBtn = false;
  let addToCalendarDropdownBtn = false;
  let startCallWithCustomerBtn = false;

  try {
    const scheduledJob = job?.status === "Scheduled"
    const inProgressJob = job?.status === "Inprogress"
    const waitingJob = job?.status === "Waiting"
    const acceptedJob = job?.status === "Accepted"
    const longJob = job.status === "long-job";

    const jobHaveNoTech = job?.technician === undefined
    const techAvailable  = job?.technician
    const userTechId = user?.technician?.id
    const isTechAlreadyDeclined = job?.tech_declined_ids.includes(userTechId)
    const isCustomerDeclinedTech = job?.declinedByCustomer.includes(userTechId)


      if (scheduledJob && (jobHaveNoTech || !techAvailable) && !isTechAlreadyDeclined) {
        scheduleJobApplyBtn = true
        scheduleJobDeclineBtn = true
      }

      if (scheduledJob && techAvailable && (techAvailable?.id == userTechId) && !isTechAlreadyDeclined) {
        cancelScheduleJobTechBtn = true;
        addToCalendarDropdownBtn = true;
      }

      if ((inProgressJob ||longJob ) && techAvailable && (techAvailable?.id == userTechId)) {
        joinJobBtn = true;
      }

      if (waitingJob && !isTechAlreadyDeclined && !isCustomerDeclinedTech) {
        acceptBtn = true;
      }
      if (acceptedJob && techAvailable && (techAvailable?.id == userTechId)) {
        startCallWithCustomerBtn = true;
      }

      return {showJobDetailsBtn ,scheduleJobApplyBtn,
        scheduleJobDeclineBtn,cancelScheduleJobTechBtn,
        addToCalendarDropdownBtn,joinJobBtn,acceptBtn,startCallWithCustomerBtn}

  } catch (error) {
    console.log(
      "error while deciding buttons list to show in dashboard:for technician",
      error
    );
    return {showJobDetailsBtn ,scheduleJobApplyBtn,
      scheduleJobDeclineBtn,cancelScheduleJobTechBtn,
      addToCalendarDropdownBtn,joinJobBtn,acceptBtn,startCallWithCustomerBtn}
  }
};



export const btnListToShowForCustomer =(job,user)=>{
  let showJobDetailsBtn  = true;
  let postJobBtn = false;
  let postAgainJobBtn = false;
  let postAgainWithSameTechBtn = false;
  let addToCalendarDropdownBtn = false;
  let cancelJobBtn = false;
  let joinJobBtn = false;
  let startCallBtn = false;
  let deleteDraftJobBtn = false;

  try {

    const draftJob = job?.status == "Draft";
    const declinedJob =  job?.status == "Declined";
    const scheduledJob = job?.status === "Scheduled"
    const completedJob = job?.status === "Completed";
    const longJob = job.status === "long-job";
    const inProgressJob = job?.status === "Inprogress"
    const acceptedJob = job?.status === "Accepted"

    
    
    const customerAvailable  = job?.customer?.user?.id
    const customerUserId = user?.id
    const jobHaveTechId = job?.technician?.id
    const sameCustomerPostJob  = (customerAvailable == customerUserId)

    if(jobHaveTechId && acceptedJob && sameCustomerPostJob){
      startCallBtn = true;
    }

    if(draftJob && sameCustomerPostJob){
      postJobBtn = true;
      deleteDraftJobBtn = true;
    }

    if(scheduledJob && sameCustomerPostJob){
      addToCalendarDropdownBtn= true;
      cancelJobBtn = true;
    }

    if(completedJob && jobHaveTechId && sameCustomerPostJob) {
      postAgainWithSameTechBtn = true;
      postAgainJobBtn = true;
    }
    if(declinedJob && sameCustomerPostJob){
      postAgainJobBtn = true;
    }
    if ((inProgressJob ||longJob ) &&  sameCustomerPostJob) {
      joinJobBtn = true;
    }

    // console.log("customerAvailablecustomerAvailable",{customerAvailable,customerUserId})

    return {showJobDetailsBtn,postJobBtn,addToCalendarDropdownBtn,cancelJobBtn,
      postAgainWithSameTechBtn,postAgainJobBtn,joinJobBtn,startCallBtn,deleteDraftJobBtn}
    
  } catch (error) {
    console.log(
      "error while deciding buttons list to show in dashboard:for customer",
      error
    );
    return {showJobDetailsBtn,postJobBtn,addToCalendarDropdownBtn,cancelJobBtn,
      postAgainWithSameTechBtn,postAgainJobBtn,joinJobBtn,deleteDraftJobBtn}
  }
}


