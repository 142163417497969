import Loader from "components/Loader";
import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { dashboardJobsData, paginationFinder } from "utils/dashboardUtilities";
import DashboardTabsDetails from "./DashboardTabDetails";
const INITIAL_PAGE = 1;
const DashboardTabs = ({
  dashboardTab,
  setDashboardTab,
  user,
  setPagination,
  setLoader,
  loader,
}) => {
  const changeTab = async (event) => {
    if (event != dashboardTab.tab) {
      setLoader(true);
      resetDashboard();
      const initialRendrer = false;
      const resultantObj = await dashboardJobsData(
        user,
        initialRendrer,
        user?.userType,
        event,
        INITIAL_PAGE
      );
      setDashboardTab(resultantObj);
      const totalJobs = resultantObj?.totalJobs ?? 0;
      const totalPages = paginationFinder(totalJobs);
      setPagination((prevState) => ({
        ...prevState,
        totalPages: totalPages,
        pageNumber: 1,
      }));
      setLoader(false);
    }
  };

  const resetDashboard = () => {
    setDashboardTab({
      tab: "live-jobs-tab",
      tabData: [],
      totalJobs: 0,
      totalJobsPerTab: {},
    });
  };

  const showFullSizeTabOrNot = () => {
    if (dashboardTab?.totalJobsPerTab) {
      const completedAndDeclinedTab =
        dashboardTab.totalJobsPerTab["completed-and-declined-tab"] == 0;
      const liveJobTab = dashboardTab.totalJobsPerTab["live-jobs-tab"] == 0;
      if (completedAndDeclinedTab || liveJobTab) {
        return false;
      } else {
        return true;
      }
    }

    return true;
  };

  return (
    <>
      <Col md="12" className="py-4 mt-1">
        {loader && (
          <Loader
            height="100%"
            className={loader ? "loader-outer" : "d-none"}
          />
        )}
        <Col xs="12" className="p-0 dashboard-tables-outer">
          <Tabs
            defaultActiveKey={dashboardTab.tab}
            activeKey={dashboardTab.tab}
            id="cust-dashboard"
            className={
              showFullSizeTabOrNot()
                ? "mb-3 tabs-outer-customer"
                : "mb-3 tabs-outer-customer-full-size"
            }
            // className={"mb-3 tabs-outer-customer"}
            onSelect={(event) => {
              changeTab(event);
            }}
          >
            {dashboardTab &&
              (dashboardTab.tab == "live-jobs-tab" ||
                dashboardTab?.totalJobsPerTab["live-jobs-tab"] != 0) && (
                <Tab
                  eventKey="live-jobs-tab"
                  title="Live Jobs"
                  className="col-md-12 p-0"
                >
                  <DashboardTabsDetails
                    user={user}
                    dashboardTab={dashboardTab}
                    setLoader={setLoader}
                  />
                </Tab>
              )}
            {dashboardTab &&
              (dashboardTab.tab == "completed-and-declined-tab" ||
                dashboardTab?.totalJobsPerTab["completed-and-declined-tab"] !=
                  0) && (
                <Tab

                  eventKey="completed-and-declined-tab"
                  title="Completed Jobs"
                  className="col-md-12 p-0"
                >
                  {dashboardTab.tab == "completed-and-declined-tab" &&
                  <DashboardTabsDetails
                    user={user}
                    dashboardTab={dashboardTab}
                    setLoader={setLoader}
                  />
                  }
                </Tab>
              )}
          </Tabs>
        </Col>
      </Col>
    </>
  );
};

export default DashboardTabs;
