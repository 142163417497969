import React from "react";
import { convertTime } from "../../utils";
import { useUser } from "context/useContext";
import { VIP_VALUES } from "../../constants";

const OfferSubscription = ({ cost, handleLinkClick }) => {
  return (
    <span style={{
      fontSize: "14px",
    }}>
      Business Plan Cost: {" "}
      <span style={{
        color: "#01d4d5",
        fontWeight: "bold",
        fontSize: "14px",
      }}>
        ${(cost * 0.5).toFixed(2)}
      </span>
      <br />
      {" "}
      <a onClick={handleLinkClick} className="vip-offer-link">
        Join any Business Plan & Save 50% off this job
      </a>
    </span>
  );
};

const LongJobFeedback = ({ longJobCost, isTechnicianSubmitFeedback, job, handleLinkClick }) => {
  const { user } = useUser();

  return (
    <>
      <tr>
        <th>Total Job Cost</th>
        <td>
          <span className="job-value">
            <>
              {" "}
              $ {longJobCost && longJobCost.totalCost}
              {isTechnicianSubmitFeedback && <> <small> (Pending Review) </small></>}
            </>
          </span>
          {user?.businessName && user?.roles.includes("owner") && !user?.customer?.subscription && (
            <>
              <br />
              <OfferSubscription cost={longJobCost.totalCost} handleLinkClick={handleLinkClick} />
            </>
          )}
        </td>
      </tr>
      {(user && user.customer && !user.customer.subscription && longJobCost && longJobCost.totalCost > 0 && !user?.businessName) ? (
        <tr>
          <th style={{
            color: "#01d4d5",
          }}> Save {VIP_VALUES.meetingOffer}%! </th>
          <td>
            <span className="job-value">
              You could have paid
              <span className="job-value offer" style={{
                color: "#01d4d5",
              }}>
                {" "}
                ${longJobCost && (longJobCost.totalCost - (longJobCost.totalCost * (VIP_VALUES.meetingOffer / 100))).toFixed(2)}
              </span>
              <br />
              {" "}
              <a onClick={handleLinkClick} className="vip-offer-link">
                Sign up & get 20% on your next job!
              </a>
            </span>
          </td>
        </tr>
      ) : null}
      {job.total_subscription_seconds > 0 && (
        <tr>
          <th>Subscription time deducted</th>
          <td>
            <span className="job-value">
              <>{convertTime(job.total_subscription_seconds)}</>
            </span>
          </td>
        </tr>
      )}
    </>
  );
};

export default LongJobFeedback;