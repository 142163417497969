import { Spin, Modal } from "antd";
import axios from "axios";
import BasicButton from "components/common/Button/BasicButton";
import SoftwareDropDown from "components/common/SoftwareDropDown";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Button } from "react-bootstrap";

import Select from "react-select";
import * as JobApi from "../../../../api/job.api";
import * as JobCycleApi from "../../../../api/jobCycle.api";
import * as SoftwareApi from "../../../../api/software.api";
import * as TechnicianApi from "../../../../api/technician.api";
import Loader from "../../../../components/Loader";
import * as PromocodeApi from "../../../../api/promoCode.api";
import { getPartnerWithShortName } from "../../../../api/partner.api";



import {
  EmailOutlook,
  INACTIVE_ACCOUNT_STATUS_MSG,
  JobTags,
  SECRET_KEY,
  FIRST_JOBPOP,
  PARTNER_CC_SKIP_PROMO,
  GEEKER_MEMBER_FIRST_JOB
} from "../../../../constants";
import { useAuth } from "../../../../context/authContext";
import { useJob } from "../../../../context/jobContext";
import {
  decryptStripeKey,
  GAevent,
  getCookie,
  isLiveUser,
  openNotificationWithIcon,
  PushUserDataToGtm,
} from "../../../../utils";
import { useResizeObserver } from "../../../../utils/index";
import AfterSelectedSoftwareModal from "../Components/AfterSelectedSoftwareModal";
import { fetchChatOrCreate, checkPendingStatus } from "../../../../utils";

let liveUser = true;
let isSoftwareEmailOrOutlook = false;
const defaultMessages = {
  "IT Specialist": "remote login and wifi issues",
  "Email \\ Outlook": "Outlook search is not searching folders correctly",
  "Google Suite":
    "I want to use the sparkline function with a filter function to filter the raw data. But I keep getting an error.",
  "QuickBooks Online":
    "My bank balance in QB is several hundred thousand dollars off from my actual bank balance. I also need help fixing some duplicated transactions that show up on a contractor i hired's 1099",
  Excel:
    "I am given questions based on an excel sheet that has been provided and I need to generate the correct formulas",
};
function SelectSoftware({
  setJobInfo,
  jobInfo,
  user,
  job,
  setGuestJobId,
  newPost,
  setIsFirsJob,
  isFirsJob,
}) {
  const [softwareList, setSoftwareList] = useState([]);
  const [currentSoftware, setCurrentSoftware] = useState(
    jobInfo.currentSoftware ? [jobInfo.currentSoftware] : []
  );
  const [currentSubSoftware, setCurrentSubSoftware] = useState(
    jobInfo.currentSubSoftware ? jobInfo.currentSubSoftware : ""
  );
  const [needThisDone, setNeedThisDone] = useState(jobInfo.needThisDone);
  const [disable, setDisable] = useState(false);
  const [disablePrevGeek, setDisablePrevGeek] = useState(false);
  const [disableDetails, setDisableDetails] = useState(true);
  const [textarea, setTextarea] = useState(jobInfo.moreDetails);
  const [characterCount, setCharacterCount] = useState(textarea.length);
  const [count, setCount] = useState(500);
  const [charLeftColor, setCharLeftColor] = useState(false);
  const [nextButton, setNextButton] = useState(true);
  const [hireValue, setHireValue] = useState(false);
  const { getGuestUser, getCustomerSubscriptionForRecordings } = useAuth();
  const { createJobAsGuest, createJob, fetchJob, getTotalJobs } = useJob();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const repostJob = queryParams.get("repost")
    ? queryParams.get("repost")
    : false;
  let technicianIdParams = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const [technicianId, setTechnicianId] = useState(technicianIdParams);
  const postAgainJobReference = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;
  const hireExpertTransferJob = queryParams.get("hireExpertTransferJob")
    ? queryParams.get("hireExpertTransferJob")
    : false;
  const transferredJob = queryParams.get("transferredJob")
    ? queryParams.get("transferredJob")
    : false;
  const technicianProfile = queryParams.get("medium")
    ? queryParams.get("medium")
    : false;
  const uniqueTechScheduleJob = queryParams.get("applyJobFor")
    ? queryParams.get("applyJobFor")
    : false;
  const editJob = queryParams.get("edit") ? queryParams.get("edit") : false;
  let newPostJob = newPost != undefined ? newPost : queryParams.get("newpost");
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTechListLoading, setIsTechListLoading] = useState(false);
  const [softwareId, setSoftwareId] = useState();
  const [subSoftwareName, setSubSoftwareName] = useState();
  const [transferJobData, setTransferJobData] = useState();
  const [selectedTechs, setSelectedTechs] = useState(
    technicianId && postAgainJobReference ? true : false
  );
  const [isSpecificSoftwareSelected, setIsSpecificSoftwareSelected] =
    useState(false);
  const [goToJobSummaryPage, setGoToJobSummaryPage] = useState(false);
  const [techniciansList, setTechniciansList] = useState([]);
  const [selectedPrevGeek, setSelectedPrevGeek] = useState();
  const [ip, setIp] = useState("");
  const getMeasurementId = decryptStripeKey(
    process.env.REACT_APP_GA_MEASUREMENT_ID
  );
  const mes_id = getMeasurementId ? getMeasurementId.split("-")[1] : false;
  const [uniqueTechSoftId, setUniqueTechSoftId] = useState([]);
  const [isTechUniqueLink, setIsTechUniqueLink] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [pendingJobModal, setPendingJobModal] = useState(false);
  const [inProgressMessage, setInProgressMessage] = useState("");
  const [technicianData, setTechnicianData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [fistJobPopup, setFistJobPop] = useState(false);
  const TECHNICIAN_STATUSES = {
    AVAILABLE: 'Available',
    IN_CALL: 'In Call',
    OFFLINE: 'Offline',
    INACTIVE: 'Inactive'
  };
  const STATUS_COLORS = {
    [TECHNICIAN_STATUSES.AVAILABLE]: '#4CAF50',
    [TECHNICIAN_STATUSES.IN_CALL]: '#F44336',
    [TECHNICIAN_STATUSES.OFFLINE]: '#FFC107',
    [TECHNICIAN_STATUSES.INACTIVE]: '#9E9E9E',
    [TECHNICIAN_STATUSES.ANY]: '#9E9E9E'
  };

  const OFFLINE_THRESHOLD_MINUTES = 0;

  const InprogressJobMsg = pendingJobModal ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      {inProgressMessage}
    </span>
  ) : (
    <span></span>
  );
  const hiddenSoftwares = localStorage.getItem("hiddenSoftwares");


  useEffect(() => {
    if (technicianId) {
      setIsTechUniqueLink(true);
      setSelectedTechs(true);
      fetchTechnicianDetails();
    } else {
      setIsTechUniqueLink(false);
      setSelectedTechs(false);
    }
  }, [technicianId]);

  useEffect(() => {
    if (currentSoftware) {
      setDisablePrevGeek(false);
      if (isTechUniqueLink) {
        fetchTechnicianDetails();
      }
    } else {
      setDisablePrevGeek(true);
      setSelectedTechs(false);
      setSelectedStatus(null);
      setSelectedPrevGeek(null);
    }
  }, [currentSoftware]);

  const fetchTechnicianDetails = async () => {
    if (technicianId) {
      try {
        const techData = await TechnicianApi.getTechnicianDetailesByUserId(technicianId);
        setUniqueTechSoftId(techData.expertise);

        const techItem = {
          techId: techData.id,
          value: techData.userId,
          label: `${techData.firstName} ${techData.lastName}`,
          status: techData.status,
          techBlocked: techData.blocked,
          user: techData
        };

        const updatedTechnicians = updateTechnicianList([techItem]);

        setTechniciansList(updatedTechnicians);
        setSelectedPrevGeek(techData.userId);
        setSelectedStatus(updatedTechnicians[0]);
      } catch (error) {
        console.error("Error fetching technician details:", error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (job) {
        setSoftwareId(job.software.id);
        setSubSoftwareName(job.subOption);
        setCurrentSoftware(job.software);
        setCurrentSubSoftware(job.subOption);
        setTextarea(job.issueDescription);
        if (transferredJob) {
          let updatedDescription =
            job.updatedIssueDescription[0].issueDescription;
          console.log("console for transfer job", {
            hireExpertTransferJob,
            newPostJob,
            job,
          });
          const transferJobData = await prepareDataForTransferJob(job, updatedDescription)
          if (transferJobData) {
            setTransferJobData(transferJobData);
          }
        }
      }
    })();
  }, [job]);

  useEffect(() => {
    (async () => {
      if (transferJobData) {
        // console.log("My console for transferJobData", transferJobData)
        // const newTransferJob = createJob(transferJobData)
        try {
          const res = await JobApi.createJob(transferJobData);
          window.location.href = `/customer/profile-setup?page=tech-search&jobId=${res.id}&transferJob=true`;
        } catch (error) {
          console.error("An error occurred:", error);
        }

      }
    })()
  }, [transferJobData])

  useEffect(() => {
    (async () => {
      try {
        let tokenFromCookie = getCookie("user_access");
        if (tokenFromCookie) {
          console.log(
            "Looking for tokenFromCookie from Select Software - found",
            // {token : "..."+tokenFromCookie.substring(tokenFromCookie.length - 10)}
          );
        } else {
          console.log(
            "Looking for tokenFromCookie from Select Software - not found"
          );
        }
        if (user) {
          //console.log("My console to check user for tech", user);
          if (user.userType === "technician") {
            // console.log("tetch token removed from selectSoftware");
            localStorage.removeItem(SECRET_KEY);
            // console.log("window.location.href from selectSoftware", user);
            window.location.href = "/";
          }
        }
        //console.log("My console to check editJob", editJob);
        localStorage.removeItem("authorizationInfo");
        const res = await SoftwareApi.getSoftwareList();
        //console.log("My console to check", res);
        if (res && res.data) {
          setSoftwareList(res.data);
          setIsLoading(false);
        }
        if (postAgainJobReference) {
          await fetchJob(postAgainJobReference);
        }
        if (user) {
          // getting total jobs of customer from DB
          const totalNumberOfJobsOfCustomer = await JobApi.getTotalJobs({
            customer: user.customer.id,
          });
          if (totalNumberOfJobsOfCustomer === 0) setIsFirsJob(true);
        }
        /*if (hireExpertTransferJob) {
          console.log("My console ------------- 1", hireExpertTransferJob);
        }*/
      } catch (e) {
        console.log("Err while getting software list --------------", e);
      }

      //console.log("My console to check technicianId", technicianId);
    })();
    //GA3 tag commented by Vinit on 24/04/2023.
    GAevent(
      "Job Initiate",
      "job_initiated",
      "job_initiated",
      user ? (user?.customer ? user.customer?.id : user.id) : "guest_user"
    );
    getIPData();
    const jobIdParams = queryParams.get("jobId");
    // we need to make sure the event is triggered only once
    if (process.env.REACT_APP_URL && !jobIdParams && job === undefined) {
      const appUrl = process.env?.REACT_APP_URL?.split("/")[2] || false;
      PushUserDataToGtm("job_initiated", user, appUrl);
    }
  }, [hiddenSoftwares]);

  useEffect(() => {
    const firstJobPartner = async () => {
      const promoCodeName = localStorage.getItem('partnerPromoCode');
      const partnerName = localStorage.getItem('partner');

      if (promoCodeName && partnerName && user) {
        const response = await PromocodeApi.retrievePromoData(promoCodeName);

        if (response && response.used_by && response.promocode_status !== "expired") {
          if (user.id) {
            const findUser = response.used_by.find(
              (item) => item.user_id === user.id
            );

            if (findUser) {
              setFistJobPop(false); // Promo code already used, so don't show the modal
            } else {
              setFistJobPop(true); // Promo code not used, show the modal
            }
          }
        }
      }

      if (!user && partnerName && promoCodeName) {
        setFistJobPop(true);
      }
    };

    firstJobPartner();
  }, [user, setFistJobPop]);

  useEffect(() => {
    (async () => {
      if (user) {
        const promoCodeName = localStorage.getItem('partnerPromoCode');
        if (!promoCodeName) {
          const partnerName = localStorage.getItem('partner');
          if (partnerName) {
            const partnerData = await getPartnerWithShortName(partnerName);
            const promoData = await PromocodeApi.retrievePromoData(partnerData?.data?.promoCode);
            console.log('partner', partnerData)
            console.log('promoData', promoData)

            if (promoData && promoData.used_by && promoData.promocode_status !== "expired") {
              if (user.id) {
                const findUser = promoData.used_by.find(
                  (item) => item.user_id === user.id
                );

                if (findUser) {
                  setFistJobPop(false); // Promo code already used, so don't show the modal
                } else {
                  setFistJobPop(true); // Promo code not used, show the modal
                  if (promoData.promo_code == PARTNER_CC_SKIP_PROMO) {
                    localStorage.setItem("firstFreePromoCode", promoData.promo_code);
                  }
                }

              }

            }
          }
        }
      }

    })()
  }, [user])

  useEffect(() => {
    (async () => {
      if (user) {
        liveUser = await isLiveUser(user)
      }
    })()
  }, [user])

  useEffect(() => {
    if (technicianId || repostJob) {
      setDisableDetails(false);
    }
  }, [technicianId, repostJob]);
  /**
   * Following function is to get ip address of customer
   * @params : none
   * @return : none
   * @author : Vinit
   **/
  const getIPData = async () => {
    try {
      const res = await axios.get(" https://geolocation-db.com/json/");
      console.log("Customer's ip is ", res.data);
      setIp(res.data.IPv4);
    } catch (error) {
      console.log("Err occured while getting ip", { error });
    }
  };

  useEffect(() => {
    (async () => {
      if (user) {
        let pendingJobs = await checkPendingStatus(user);
        //console.log("pending jobs ::", pendingJobs);
        if (pendingJobs.success) {
          if (
            pendingJobs.status === "Inprogress" ||
            pendingJobs.status === "Accepted"
          ) {
            setPendingJobModal(true);
            setInProgressMessage(
              <span
                className="div-font"
                style={{ fontSize: 20, paddingTop: "40px" }}
              >
                One of your job of{" "}
                <b style={{ fontWeight: "bold" }}>{pendingJobs.name}</b> is
                already{" "}
                <b style={{ fontWeight: "bold" }}>{pendingJobs.status}</b> with
                a technician. Please complete that job first then post a new
                job.
              </span>
            );
          }
        }
      }
    })();
  }, [user]);

  const checkTechnicianStatus = (technician) => {
    const now = new Date();

    if (technician.user && technician.user.lastLogin) {
      const lastLoginTime = new Date(technician.user.lastLogin);
      const minutesSinceLastLogin = (now - lastLoginTime) / (1000 * 60);

      if (minutesSinceLastLogin > OFFLINE_THRESHOLD_MINUTES && !technician.user.isOnline) {
        return TECHNICIAN_STATUSES.OFFLINE;
      }
      return technician.status === "Busy" ? TECHNICIAN_STATUSES.IN_CALL : TECHNICIAN_STATUSES.AVAILABLE;
    }

    return TECHNICIAN_STATUSES.OFFLINE;
  };

  const updateTechnicianList = (techniciansList) => {
    return techniciansList.map(tech => {
      if (tech.value === "Any") return tech;

      const status = checkTechnicianStatus(tech);
      return {
        ...tech,
        online: status === TECHNICIAN_STATUSES.AVAILABLE,
        status: status
      };
    });
  };

  const fetchTechnicians = async () => {
    if (!currentSoftware) {
      setTechniciansList([]);
      setIsTechListLoading(false);
      return;
    }

    setIsTechListLoading(true);
    const defaultTechnician = { techId: "Any", value: "Any", label: "Any Geek" };
    let technicians = [defaultTechnician];

    if (!user) {
      setTechniciansList(technicians);
      setIsTechListLoading(false);
      return;
    }

    try {
      const [allActiveTechnicians, allCustomerJobsRes] = await Promise.all([
        TechnicianApi.getOnlineTechnicians({ softwares: currentSoftware.id }),
        JobApi.findAllJobsByParams({ customer: user.customer.id })
      ]);

      const technicianSet = new Set();

      allCustomerJobsRes.jobs.data.forEach((job) => {
        if (job?.technician?.id && job?.technician?.user) {
          const techFoundInActiveTechs = allActiveTechnicians.data.some(tech => tech.id === job.technician.id);
          if (techFoundInActiveTechs) {
            const { id: techId, status } = job.technician;
            const { id: value, firstName, lastName, blocked: techBlocked } = job.technician.user;
            const label = `${firstName} ${lastName}`;

            if (!technicianSet.has(value)) {
              technicianSet.add(value);
              technicians.push({
                techId,
                value,
                label,
                status,
                techBlocked,
                user: job.technician.user
              });
            }
          }
        }
      });

      const updatedTechnicians = updateTechnicianList(technicians);

      setTechniciansList(updatedTechnicians.filter(tech => !tech.techBlocked));
    } catch (error) {
      console.error("Error fetching technicians:", error);
      setTechniciansList([defaultTechnician]);
    } finally {
      setIsTechListLoading(false);
    }
  };

  const handleFindPreviousGeek = () => {
    if (disablePrevGeek) {
      openNotificationWithIcon("error", "Error", "Please select software first.");
      return;
    }

    setSelectedTechs(true);
    fetchTechnicians();
  };

  useEffect(() => {
    if (jobInfo && jobInfo.needThisDone.length > 0) {
      setNextButton(false);
      if (jobInfo.moreDetails.length > 0) {
        setDisableDetails(false);
      }
    }
    setSoftwareId(jobInfo.currentSoftware.id);
    setSubSoftwareName(jobInfo.currentSubSoftware);
    setCurrentSoftware(jobInfo.currentSoftware);
  }, [jobInfo]);

  useEffect(() => {
    (async () => {
      if (user) {
        liveUser = await isLiveUser(user);
      }
    })();
  }, [user]);

  useEffect(() => {
    //console.log("Console in main useEffect 1");
    if (
      currentSoftware &&
      currentSoftware.sub_option &&
      currentSoftware.sub_option.length > 0
    ) {
      //console.log("Console in main useEffect 2", currentSoftware);
      setDisable(false);
      setDisablePrevGeek(false);
      // setNextButton(false)
    } else {
      //console.log("Console in main useEffect 3", currentSoftware);
      setDisable(true);
      setDisablePrevGeek(true);
      // setNextButton(true)
    }

    // if(currentSubSoftware && currentSubSoftware.length > 0 ){
    if (currentSubSoftware && currentSubSoftware !== "Select") {
      //console.log("Console in main useEffect 4", currentSubSoftware);
      setDisableDetails(false);
      // setNextButton(false)
    } else {
      //console.log("Console in main useEffect 5", currentSubSoftware);
      // setDisableDetails(true)
      // setNextButton(true)
    }

    if (textarea && textarea.length > 0) {
      //console.log("Console in main useEffect 6", textarea);
      // setNextButton(true)
      setNextButton(false);
    } else {
      //console.log("Console in main useEffect 7", textarea);
      setNextButton(true);
      setNeedThisDone("");
    }

    if (textarea && textarea.length > 450) {
      //console.log("Console in main useEffect 8", textarea, textarea.length);
      setCharLeftColor(true);
    } else {
      //console.log("Console in main useEffect 9", textarea, textarea.length);
      setCharLeftColor(false);
    }

    if (textarea && textarea.length > 450) {
      //console.log("Console in main useEffect 12", textarea, textarea.length);
      setCharLeftColor(true);
    } else {
      //console.log("Console in main useEffect 13", textarea, textarea.length);
      setCharLeftColor(false);
    }
  }, [currentSubSoftware, currentSoftware, textarea]);

  useEffect(() => {
    (async (e) => {
      if (goToJobSummaryPage) {
        await nextJobSummaryPageHandler(e);
      }
    })();
  }, [goToJobSummaryPage]);

  const sizeRef = useResizeObserver(({ width, height }) => {
    // console.log(
    //   `Inside selectSoftware Element width: ${width}, height: ${height}`
    // );
  });

  const prepareDataForTransferJob = async (job, updatedDescription) => {
    let jobDataToCreateNewTransferJob = {};
    if (user) {
      let customer_subs = await getCustomerSubscriptionForRecordings(user);
      if (customer_subs && customer_subs?.subscription && customer_subs?.subscription?.plan_id) {
        jobDataToCreateNewTransferJob.has_free_recording = true;
      }
    }
    jobDataToCreateNewTransferJob.transfer_reference_job = job?.id;
    jobDataToCreateNewTransferJob.customer = user?.customer?.id
      ? user?.customer?.id
      : user?.customer;
    jobDataToCreateNewTransferJob.software = job?.software?.id;
    jobDataToCreateNewTransferJob.expertise = job?.software?.expertise;
    jobDataToCreateNewTransferJob.subOption = job?.subOption;
    jobDataToCreateNewTransferJob.issueDescription = updatedDescription
      ? updatedDescription
      : job?.issueDescription;
    jobDataToCreateNewTransferJob.level = "advanced";
    jobDataToCreateNewTransferJob.estimatedTime = job?.software
      ? job?.software.estimatedTime
      : "0-0";
    if (hireExpertTransferJob) {
      jobDataToCreateNewTransferJob.estimatedPrice = job?.software
        ? job?.software.twoTierEstimatePrice
        : "0-0";
    } else {
      jobDataToCreateNewTransferJob.estimatedPrice = job?.software
        ? job?.software.estimatedPrice
        : "0-0";
    }
    if (user?.customer?.subscription?.vip_membership) {
      const isFirstJob = !user.customer.subscription.isFirstJobCompleted;
      const vipDiscount = isFirstJob ? GEEKER_MEMBER_FIRST_JOB : user.customer.subscription.discount;

      jobDataToCreateNewTransferJob["has_vip_membership"] = true;
      jobDataToCreateNewTransferJob["vip_discount"] = Number(vipDiscount);
    }

    jobDataToCreateNewTransferJob.status = "Pending";
    jobDataToCreateNewTransferJob.transfer_on_dashboard = false;
    jobDataToCreateNewTransferJob.is_transferred = true;
    jobDataToCreateNewTransferJob.hire_expert = hireExpertTransferJob;
    jobDataToCreateNewTransferJob.is_transferred_hire_expert =
      hireExpertTransferJob;
    // If this is true then we will not send any notification to that particular tech who declined the job
    jobDataToCreateNewTransferJob.is_transferred_notification_sent = true;
    jobDataToCreateNewTransferJob.ownerId = job?.ownerId;
    jobDataToCreateNewTransferJob["tech_declined_ids"] = job?.tech_declined_ids;
    jobDataToCreateNewTransferJob.reasons = job?.reasons;
    jobDataToCreateNewTransferJob.technician_notes =
      job?.technician_notes.length > 0 ? job?.technician_notes : [];

    const getParentJob = job?.parent_job ?? job?.transfer_reference_job ?? job?.id
    jobDataToCreateNewTransferJob.parent_job = getParentJob
    return jobDataToCreateNewTransferJob;
  };


  const handleTechniciansList = (selectedOption) => {
    setSelectedPrevGeek(selectedOption.value);
    setSelectedStatus(selectedOption);
    setTechnicianData(null);
  };

  const getStatusColor = (status) => STATUS_COLORS[status] || STATUS_COLORS[TECHNICIAN_STATUSES.ANY];

  const StatusIndicator = ({ status }) => (
    <span className="inline-block text-lg mr-2" style={{ color: getStatusColor(status) }}>•</span>
  );

  const getLastLoginText = (lastTimestamp) => {
    const now = new Date();
    const logTime = new Date(lastTimestamp);
    const diffInHours = (now - logTime) / (1000 * 60 * 60);

    if (diffInHours < 3) {
      const minutes = Math.floor(diffInHours * 60);
      return <>Last time seen <span style={{ fontWeight: 'bold' }}>{minutes}</span> minute{minutes !== 1 && 's'} ago</>;
    } else if (diffInHours < 8) {
      const hours = Math.floor(diffInHours);
      return <>Last time seen <span style={{ fontWeight: 'bold' }}>{hours}</span> hour{hours !== 1 && 's'} ago</>;
    } else if (diffInHours < 24) {
      return 'Last time seen less than a day ago';
    } else if (diffInHours < 120) {
      const days = Math.floor(diffInHours / 24);
      return <>Last time seen <span style={{ fontWeight: 'bold' }}>{days}</span> day{days !== 1 && 's'} ago</>;
    } else if (diffInHours < 168) {
      return 'Last time seen less than a week ago';
    } else if (diffInHours < 1008) {
      const weeks = Math.floor(diffInHours / 168);
      return <>Last time seen <span style={{ fontWeight: 'bold' }}>{weeks}</span> week{weeks !== 1 && 's'} ago</>;
    } else {
      return 'Last seen more than a month ago';
    }
  };

  const formatOptionLabel = ({ value, label, status, user }) => {
    if (value === "Any") {
      return <div className="flex items-center">Any Geek</div>;
    }

    const color = getStatusColor(status);
    const showLastLogin = status === TECHNICIAN_STATUSES.OFFLINE || status === TECHNICIAN_STATUSES.INACTIVE;
    const lastLoginText = showLastLogin && user?.lastLogin ? getLastLoginText(user.lastLogin) : '';

    return (
      <div className="flex items-center">
        <StatusIndicator status={status} />
        <span className="font-medium">{label}</span>
        <span className="ml-1" style={{ color }}>{` – ${status}`}</span>
        <br />
        {showLastLogin && lastLoginText &&
          <small style={{
            color: '#9E9E9E',
            fontSize: '0.8rem',
            marginLeft: '0.9rem'
          }}
          >{lastLoginText}</small>}
      </div>
    );
  };

  /**
   * Following function will check if user made any changes  in the existing job data
   * @params = none
   * @response : Boolean
   * @author : Vinit
   */
  const checkIfDataChanged = () => {
    if (
      softwareId === job.software.id &&
      subSoftwareName === job.subOption &&
      textarea === job.issueDescription &&
      selectedPrevGeek === job?.post_again_reference_technician
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    removeStorageFromPendingChat();
  }, []);

  // This function will remove localStorage for job whose status is pending and admin sent message to it ~Jagroop
  const removeStorageFromPendingChat = () => {
    try {
      const haveLocalStorage =
        window.localStorage.getItem("pendingJobHaveChat");
      if (haveLocalStorage) {
        window.localStorage.removeItem("pendingJobHaveChat");
      }
      return;
    } catch (error) {
      console.error("error while removing pending job chat storage", error);
      return;
    }
  };

  const nextBtnHandler = async (e) => {
    setShowSpinner(true);
    e.preventDefault();
    let popupRequiredOptions = [
      "Local printer and scanner",
      "Network printer and scanner",
    ];
    if (
      popupRequiredOptions.indexOf(currentSubSoftware) != -1 ||
      currentSoftware?.id === EmailOutlook
    ) {
      setIsSpecificSoftwareSelected(true);
      if (currentSoftware?.id === EmailOutlook) {
        isSoftwareEmailOrOutlook = true;
      }
    } else {
      await nextJobSummaryPageHandler(e);
    }
  };

  const nextJobSummaryPageHandler = async (e) => {
    if (user && user.blocked) {
      return openNotificationWithIcon(
        "error",
        "Error",
        "You're blocked by the admin."
      );
    }
    if (user && !user.activeStatus) {
      setShowSpinner(false);
      return openNotificationWithIcon(
        "info",
        "Info",
        INACTIVE_ACCOUNT_STATUS_MSG
      );
    }
    if (editJob) {
      if (user) console.log("user at software selection", user);
      const dataChanged = checkIfDataChanged();
      if (dataChanged) {
        const dataToSave = {};
        if (selectedPrevGeek && selectedPrevGeek !== "Any") {
          dataToSave["post_again_reference_job"] = postAgainJobReference;
          dataToSave["post_again_reference_technician"] = selectedPrevGeek
        } else {
          dataToSave["post_again_reference_job"] = ""
          dataToSave["post_again_reference_technician"] = ""
        }
        dataToSave.software = softwareId;
        dataToSave.subOption = subSoftwareName;
        dataToSave.issueDescription = textarea;
        // updating changed or edited data
        const updateJobRes = await JobApi.updateJob(postAgainJobReference, dataToSave);
        // const updateJobRes = await JobApi.updateJob(postAgainJobReference, {
        //   software: softwareId,
        //   subOption: subSoftwareName,
        //   issueDescription: textarea,
        // });
        if (updateJobRes) {
          setShowSpinner(false);
          if (selectedPrevGeek && selectedPrevGeek !== "Any") {
            if (user) {
              window.location.href = `/customer/profile-setup?page=job-summary&jobId=${postAgainJobReference}&technicianId=${selectedPrevGeek}`;
            } else {
              window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${postAgainJobReference}&technicianId=${selectedPrevGeek}`;
            }
          } else {
            if (user) {
              window.location.href = `/customer/profile-setup?page=job-summary&jobId=${postAgainJobReference}`;
            } else {
              window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${postAgainJobReference}`;
            }
          }
        } else {
          setShowSpinner(false);
          openNotificationWithIcon("error", "Error", "Please try again.");
        }
      } else {
        if (selectedPrevGeek && selectedPrevGeek !== "Any") {
          if (user) {
            window.location.href = `/customer/profile-setup?page=job-summary&jobId=${postAgainJobReference}&technicianId=${selectedPrevGeek}`;
          } else {
            window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${postAgainJobReference}&technicianId=${selectedPrevGeek}`;
          }
        } else {
          if (user) {
            window.location.href = `/customer/profile-setup?page=job-summary&jobId=${postAgainJobReference}`;
          } else {
            window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${postAgainJobReference}`;
          }
        }
      }
    } else {
      const dataToSave = {};
      dataToSave.software = currentSoftware?.id;
      dataToSave.subOption = currentSubSoftware;
      dataToSave.issueDescription = textarea;
      dataToSave.level = "advanced";
      dataToSave.estimatedTime = currentSoftware
        ? currentSoftware.estimatedTime
        : "0-0";
      dataToSave.estimatedPrice = currentSoftware
        ? currentSoftware.estimatedPrice
        : "0-0";
      dataToSave.hire_expert = hireValue;
      dataToSave.client_id = String(
        getCookie("_ga").split(".")[2] + "." + getCookie("_ga").split(".")[3]
      );
      dataToSave.session_id = String(getCookie(`_ga_${mes_id}`).split(".")[2]);
      dataToSave.facebook_fbp = String(getCookie("_fbp"));
      dataToSave.facebook_fbc = String(getCookie("_fbc"));
      dataToSave.status = "Draft";
      dataToSave.user_agent = navigator.userAgent;
      dataToSave.customer_ip = ip;
      dataToSave.ownerId = user?.ownerId ? user?.ownerId : user?.id;

      if (selectedPrevGeek && selectedPrevGeek !== "Any") {
        dataToSave["post_again_reference_job"] = job?.id;
        dataToSave["post_again_reference_technician"] = selectedPrevGeek
          ? selectedPrevGeek
          : false;
      }
      let draftJobData;
      if (!user || user.email === "guest@geeker.co") {
        localStorage.removeItem("isScheduleJob");
        // console.log("No user exists!");
        dataToSave.customer = `guest_${new Date().getTime()}`;
        dataToSave.guestJob = true;
        const res = await getGuestUser();
        // console.log("Guest user response", res);
        if (res && res.token) {
          // console.log("My console - res & res.token", res);
          await createJobAsGuest(dataToSave, res.token.accessToken).then(
            async (res) => {
              mixpanel.track("Customer guest Job Created", { JobID: res.id });
              setGuestJobId(res.id);
              // creating jobCycle
              await JobCycleApi.create(JobTags.DRAFT_JOB_CREATED, res.id);
              // console.log(">>>>>>>>>>>>>>>>>>> 1  >>>>>>>>>>>>", res);
              draftJobData = res;
              console.log(
                "window.location.href from Selectsoftwares with guest user",
                res
              );
              if (selectedPrevGeek && selectedPrevGeek !== "Any") {
                window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${res.id}&technicianId=${selectedPrevGeek}`;
                setTimeout(() => {
                  window.location.replace(
                    `/customer/start-profile-setup?page=job-summary&jobId=${res.id}&technicianId=${selectedPrevGeek}`
                  );
                }, 3000);

                if (uniqueTechScheduleJob) {
                  window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${res.id}&technicianId=${selectedPrevGeek}&applyJobFor=${uniqueTechScheduleJob}`;
                  setTimeout(() => {
                    window.location.replace(
                      `/customer/start-profile-setup?page=job-summary&jobId=${res.id}&technicianId=${selectedPrevGeek}&applyJobFor=${uniqueTechScheduleJob}`
                    );
                  }, 3000);
                }
              } else {
                window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${res.id}`;
                setTimeout(() => {
                  window.location.replace(
                    `/customer/start-profile-setup?page=job-summary&jobId=${res.id}`
                  );
                }, 3000);
              }
            }
          );
        }
      } else {
        localStorage.removeItem("isScheduleJob");
        // console.log("user exists!", user);
        dataToSave.customer = user.customer.id
          ? user.customer.id
          : user.customer;
        dataToSave.guestJob = false;
        draftJobData = await createJob(dataToSave);
        // console.log("window.location.href from selectsoftwares", draftJobData);
        if (technicianIdParams && repostJob) {
          await JobCycleApi.create(
            JobTags.POST_AGAIN_WITH_SAME_TECH,
            draftJobData.id
          );
        } else if (repostJob) {
          await JobCycleApi.create(JobTags.POST_AGAIN, draftJobData.id);
        } else {
          await JobCycleApi.create(JobTags.JOB_CREATED, draftJobData.id);
        }
        // console.log("my selected geek ::: ", selectedPrevGeek);
        if (selectedPrevGeek && selectedPrevGeek !== "Any") {
          window.location.href = `/customer/profile-setup?page=job-summary&jobId=${draftJobData.id}&technicianId=${selectedPrevGeek}`;
          setTimeout(() => {
            window.location.replace(
              `/customer/profile-setup?page=job-summary&jobId=${draftJobData.id}&technicianId=${selectedPrevGeek}`
            );
          }, 3000);
          if (uniqueTechScheduleJob) {
            window.location.href = `/customer/profile-setup?page=job-summary&jobId=${draftJobData.id}&technicianId=${selectedPrevGeek}&applyJobFor=${uniqueTechScheduleJob}`;
            setTimeout(() => {
              window.location.replace(
                `/customer/profile-setup?page=job-summary&jobId=${draftJobData.id}&technicianId=${selectedPrevGeek}&applyJobFor=${uniqueTechScheduleJob}`
              );
            }, 3000);
          }
        } else {
          window.location.href = `/customer/profile-setup?page=job-summary&jobId=${draftJobData.id}`;
          setTimeout(() => {
            window.location.replace(
              `/customer/profile-setup?page=job-summary&jobId=${draftJobData.id}`
            );
          }, 3000);
        }
      }

      // console.log("My console to check dataToSave", dataToSave);
      setJobInfo({
        currentSoftware,
        currentSubSoftware,
        needThisDone,
        moreDetails: textarea,
      });
      // console.log("testing console for draftJobRes", draftJobData);
    }
  };

  useEffect(() => {
    setCharacterCount(textarea?.length);
  }, [textarea]);

  const handleChange = (event) => {
    const data = event.target.value.trim();
    if (data === "") {
      setTextarea("");
    } else {
      setTextarea(event.target.value);
    }
  };

  const getMessageForSoftware = (softwareName) => {
    if (defaultMessages.hasOwnProperty(softwareName)) {
      return defaultMessages[softwareName];
    }
  };

  const onSoftwareSelection = (softId) => {
    setPlaceholder("");
    const currSoftware = softwareList.find((item) => item.id === softId);
    const message = getMessageForSoftware(currSoftware.name);
    setPlaceholder(message);
    setCurrentSoftware(currSoftware);
    setSoftwareId(currSoftware.id);
    // console.log("this is current :::", softwareId);
    setCurrentSubSoftware();
    setSubSoftwareName("Select");
    setDisableDetails(true);
    setTextarea("");
    setSelectedTechs(false);
    // setSelectedPrevGeek();
    // setSelectedStatus(null);
    const selectedSoftwareId = uniqueTechSoftId.map((softId) => {
      return softId.software_id;
    });
    // if technicianId is already selected then don't change it or change the techs dropdown
    if(technicianId){
      return;
    }
    // if selected software is in the list of uniqueTechSoftId then set technicianId
    if (selectedSoftwareId.includes(softId)) {
      setTechnicianId(technicianIdParams);
      setSelectedPrevGeek(technicianIdParams);
      setIsTechUniqueLink(true);
    } else {
      setTechnicianId(false);
      setIsTechUniqueLink(false);
      // setTechniciansList([]);
    }
  };

  const onSubSoftwareSelection = (value) => {
    // console.log("onSubSoftwareSelection>>>>",value)
    setCurrentSubSoftware(value);
    setSubSoftwareName(value);
    // const currSoftware = softwareList.find(item => item.id === softwareId)
    // const selectedSubSoftware = currSoftware.sub_option.find((sub) => sub.name === value);

    //  setPlaceholder(selectedSubSoftware.placeholder);
    if (currentSoftware) {
      const selectedSubSoftware = currentSoftware.sub_option.find(
        (sub) => sub.name === value
      );
      if (selectedSubSoftware) {
        setPlaceholder(selectedSubSoftware.placeholder);
      }
    }
  };
  const closePendingModal = () => {
    setPendingJobModal(false);
    window.location.href = "/dashboard";
  };

  const getTechnicianAvailabilityMessage = (technician) => {
    if (!technician) return null;
    if (technician.value === "Any") return null;

    const firstName = technician.label.split(' ')[0];

    if (technician.status === "Busy") {
      return `Sorry, ${firstName} is helping another customer right now. We will message them as soon as you post your job and will help you find another Geek if they don't respond.`;
    } else if (technician.online) {
      return `Great news! ${firstName} is available! You should be connected within a few minutes after posting.`;
    } else {
      return `Sorry, ${firstName} is not online right now. We will message them as soon as you post your job or help you find another Geek if they don't respond.`;
    }
  };

  if (isLoading || transferredJob) return <Loader height="100%" />;

  return (
    <React.Fragment key="dropdown">
      <Modal
        visible={fistJobPopup}

        footer={[
          <Button
            key="submit"
            type="primary"
            className="btn app-btn job-accept-btn modal-footer-btn"
            onClick={() => setFistJobPop(false)}
          >
            OK
          </Button>,
        ]}
        className="responsive-modal"
        closable={false}
      >
        <p className="modal-text">
          You've got a free job! Just fill out your info and we will have a technician helping you in minutes.
        </p>
      </Modal>
      <Modal
        className="get-help-now-modal"
        closable={true}
        onCancel={closePendingModal}
        visible={pendingJobModal}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              id="confirm-create-new"
              className="btn app-btn job-accept-btn modal-footer-btn"
              onClick={closePendingModal}
              key="yes"
            >
              <span></span>Back To Dashbord
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {InprogressJobMsg}
          </span>
        </div>
      </Modal>
      <form>
        <div ref={sizeRef}>
          <div className="d-flex justify-content-center margin-bottom-25">
            <div style={{ width: "100%", maxWidth: "600px", height: "106px" }}>
              <div className="softare-label-div">
                <label className="softare-label">Category:</label>
              </div>
              <div id="softwares">
                <SoftwareDropDown
                  dropDownOptions={softwareList}
                  onSoftwareSelection={onSoftwareSelection}
                  value={jobInfo.currentSoftware.id}
                  name={"softwares"}
                  currentSoftware={currentSoftware}
                  job={job}
                  subSoftwareName={subSoftwareName}
                  softwareId={softwareId}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center margin-bottom-25">
            <div style={{ width: "100%", maxWidth: "600px", height: "106px" }}>
              <div
                className={`${disable ? "opacity-point-5" : ""
                  } softare-label-div`}
              >
                <label className="softare-label-n">and I need help with:</label>
              </div>
              <div
                id="subsoftwares"
                className={`${disable ? "opacity-point-5" : " "}`}
              >
                <SoftwareDropDown
                  disable={disable}
                  dropDownOptions={
                    currentSoftware.sub_option ? currentSoftware.sub_option : []
                  }
                  onSubSoftwareSelection={onSubSoftwareSelection}
                  name={`subsoftwares`}
                  value={jobInfo.currentSubSoftware.name}
                  currentSubSoftware={currentSubSoftware}
                  job={job}
                  subSoftwareName={subSoftwareName}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center margin-bottom-15">
            <div style={{ width: "100%", maxWidth: "600px" }}>
              <div
                className={`${disableDetails ? "opacity-point-5" : ""
                  } softare-label-div`}
              >
                <label className="softare-label-n">More details:</label>
              </div>
              <div className={`${disableDetails ? "opacity-point-5" : " "}`}>
                <textarea
                  id="issue-description"
                  // maxLength="500"
                  disabled={disableDetails}
                  type="text"
                  className="software-more-details-input"
                  value={textarea}
                  onChange={handleChange}
                  placeholder={
                    placeholder ? `Sample description : ${placeholder}` : ""
                  }
                  key={"MyTextAreaKey"}
                />
              </div>
            </div>
          </div>

          {/* <div className={`d-flex justify-content-center margin-bottom-61 ${disableDetails ? 'opacity-point-5' : ''}`}>
                    <div style={{ width: "100%", maxWidth: "600px", }}>
                        <span className={`character ${charLeftColor ? "red-character" : " "}`}> ({`${count - characterCount} character left`}) </span>
                    </div>
                </div> */}

          {
            <div className="max-w-60p margin-auto">
              <div className="p-0-30-10-25 media-max-width-500-padding-lr-0 mb-4">
                <div className="softare-label-div margin-bottom-15">
                  {(!user || (user && isFirsJob)) && (
                    <>
                      <span
                        className="softare-label-n2"
                        style={{ cursor: "not-allowed", color: "#d9d9d9" }}
                        title="No previous geeks available"
                      >
                        Find a previous geek <small>(optional)</small>
                      </span>{" "}
                      <span className="no-prev-geeks">
                        (no previous geeks available)
                      </span>
                    </>
                  )}
                  {user && !isFirsJob && !isTechUniqueLink && (
                    <span
                      onClick={handleFindPreviousGeek}
                      className="softare-label-n2"
                      style={{ cursor: disablePrevGeek ? "not-allowed" : "pointer" }}
                    >
                      Find a previous geek <small>(optional)</small>
                    </span>
                  )}
                  {isTechUniqueLink && (
                    <>
                      <span
                        className="softare-label-n2"
                        style={{ cursor: "default" }}
                      >
                        Selected geek
                      </span>
                    </>
                  )}
                </div>

                {(selectedTechs || isTechUniqueLink) && (
                  <div className="jobSummaryDropDownDiv">
                    {isTechListLoading ? (
                      <Spin className="job-summary-spinner" />
                    ) : (
                      <Select
                        options={techniciansList}
                        className="jobSummaryDropDown media-max-width-500-mt-20"
                        isSearchable={false}
                        onChange={handleTechniciansList}
                        formatOptionLabel={formatOptionLabel}
                        value={selectedStatus}
                        isDisabled={isTechUniqueLink || !currentSoftware}
                        placeholder="Select a geek"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          }
          {selectedStatus && (
            <div className="d-flex justify-content-center margin-bottom-15">
              <div style={{ width: "100%", maxWidth: "600px" }}>
                <p style={{ marginTop: '10px', textAlign: 'justify' }}>
                  {getTechnicianAvailabilityMessage(selectedStatus)}
                </p>
              </div>
            </div>
          )}
          {technicianData && (
            <div className="d-flex justify-content-center margin-bottom-15">
              <div style={{ width: "100%", maxWidth: "600px" }}>
                {getTechnicianAvailabilityMessage(selectedStatus)}
              </div>
            </div>
          )}


          <div className="d-flex justify-content-center mb-50">
            <div
              className="d-flex justify-content-end"
              style={{ width: "100%", maxWidth: "600px" }}
            >
              <BasicButton
                id="softwares-next-btn"
                disable={nextButton || showSpinner}
                onClick={nextBtnHandler}
                btnTitle={"Next"}
                height={"60px"}
                width={"158px"}
                background={"#01D4D5"}
                color={"#fff"}
                showSpinner={showSpinner}
              />
            </div>
          </div>
        </div>
      </form>
      {/* show modal after specific software selected */}
      <AfterSelectedSoftwareModal
        isSpecificSoftwareSelected={isSpecificSoftwareSelected}
        setIsSpecificSoftwareSelected={setIsSpecificSoftwareSelected}
        setGoToJobSummaryPage={setGoToJobSummaryPage}
        isSoftwareEmailOrOutlook={isSoftwareEmailOrOutlook}
        setNextButton={setNextButton}
        setShowSpinner={setShowSpinner}
      />
    </React.Fragment>
  );
}

export default SelectSoftware;
