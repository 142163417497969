import React from "react";
import { VIP_VALUES } from "../../constants";
import { useUser } from "context/useContext";

const OfferSubscription = ({ cost, handleLinkClick }) => {
  return (
    <span style={{
      fontSize: "14px",
    }}>
      Business Plan Cost: {" "}
      <span style={{
        color: "#01d4d5",
        fontWeight: "bold",
        fontSize: "14px",
      }}>
        ${(cost * 0.5).toFixed(2)}
      </span>
      <br />
      {" "}
      <a onClick={handleLinkClick} className="vip-offer-link">
        Join any Business Plan & Save 50% off this job
      </a>
    </span>
  );
};

const SixMinFreeJobFeedback = ({
  sixMinFreeJobCost,
  isTechnicianSubmitFeedback,
  handleLinkClick
}) => {
  const { user } = useUser();

  return (
    <>
      <tr>
        <th>Total Job Cost</th>
        <td>
          <span className="job-value">
            <> $ {sixMinFreeJobCost && sixMinFreeJobCost.totalCost}</>
          </span>
        </td>
      </tr>
      <tr>
        <th>Amount to be Paid</th>
        <td>
          <span className="job-value">
            {sixMinFreeJobCost && sixMinFreeJobCost.promoCodeApplied ? (
              <>
                <strike>
                  <span className="job-value totalAmount">
                    {" "}
                    $ {sixMinFreeJobCost.amountToBePaid}
                  </span>
                </strike>
                <span className="job-value">
                  {" "}
                  $ {sixMinFreeJobCost.discountedCost}
                  {isTechnicianSubmitFeedback && (
                    <> <small> (Pending Review) </small> </>
                  )}
                </span>
                {user?.businessName && user?.roles.includes("owner") && !user?.customer?.subscription &&
                  <OfferSubscription cost={sixMinFreeJobCost.discountedCost} handleLinkClick={handleLinkClick} />
                }
              </>
            ) : (
              <>
                {" "}
                $ {sixMinFreeJobCost && sixMinFreeJobCost.amountToBePaid}
                {isTechnicianSubmitFeedback && (
                  <small> (Pending Review) </small>
                )}
                {user?.businessName && user?.roles.includes("owner") && !user?.customer?.subscription &&
                  <OfferSubscription cost={sixMinFreeJobCost.amountToBePaid} handleLinkClick={handleLinkClick} />
                }
              </>
            )}
          </span>
        </td>
      </tr>
      {(user && user.customer && !user.customer.subscription && sixMinFreeJobCost && sixMinFreeJobCost.amountToBePaid > 0 && !user?.businessName) ? (
        <>
          <tr>
            <th style={{ 
              color: "#01d4d5",
            }}> Save {VIP_VALUES.meetingOffer}%! </th>
            <td>
              <span className="job-value">
                {sixMinFreeJobCost && sixMinFreeJobCost.promoCodeApplied ? (
                  <>
                    {" "}
                    You could have paid
                    <span className="job-value offer">
                      {" "}
                      $ {(sixMinFreeJobCost.discountedCost * 0.80).toFixed(2)}
                    </span>
                  </>
                ) : (
                  <>
                    {" "}
                    You could have paid
                    <span className="job-value offer">
                      {" "}
                      $ {(sixMinFreeJobCost.amountToBePaid * 0.80).toFixed(2)}
                    </span>
                  </>
                )}
                <br />
                {" "}
                <a onClick={handleLinkClick} className="vip-offer-link">
                  Sign up & get 20% on your next job!
                </a>
              </span>
            </td>
          </tr>
        </>
      ) : null}
    </>
  );
};

export default SixMinFreeJobFeedback;