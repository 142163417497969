import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import React from 'react'
import './style.css'
import { useUser } from 'context/useContext'

const ScheduleJobModal = ({ openScheduleModal, setOpenScheduleModal, scheduleForLater, isFromMobile, handelScheduleForLater, isDisabled, isRescheduled }) => {

    const handleClose = () => {
        setOpenScheduleModal(false);
    }
    const userData = useUser()

    return (
        <>
            <div>
                <Modal
                    open={openScheduleModal}
                    onClose={() => { setOpenScheduleModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="outer-box-modal1">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} className="close-icon-btn" style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="text-div mg-b-10">
                            <div className="end-the-job-div">
                                {(userData?.user?.roles[0] !== "owner" && userData?.user?.roles[0] !== "admin") ? (<>
                                    <span className="close-icon-btn-text font-nova">
                                        Please make sure to attend your scheduled meeting (note that once a schedule is accepted, your company may incur a small fee for no-shows or last-minute cancellations, so we can keep our technicians happy)
                                    </span>
                                </>) : (<>
                                    {isRescheduled && isFromMobile ?
                                        <span className="close-icon-btn-text font-nova">Please note, we offer free reschedule up to 3 hours before your meeting. However your scheduled job is less than 3 hours away. If you reschedule now, a $24.99 fee will apply</span>
                                        : <span className="close-icon-btn-text font-nova">We will work to find you a Geek who can assist you at your desired time. Please note, you can cancel or reschedule the job up to 2 hours before your meeting at no additional charge. However, if your selected time is less than 2 hours away and you cancel after scheduling this job, a $24.99 "no-show" fee will apply. If you schedule outside of business hours, please be aware that it may be more difficult to find an available technician unless you have already confirmed with one.</span>
                                    }
                                </>)}
                            </div>

                        </div>

                        <div className="d-flex justify-content-end buttons-container mg-b-10 ">
                            <div className="mg-b-10">
                                <Stack className='margin-right-16px' spacing={2} direction="row">
                                    <Button variant="outlined" className="Outlined-btn-join" onClick={() => { setOpenScheduleModal(false) }}>
                                        <span className="join-btn-css">Don’t schedule</span>
                                    </Button>
                                </Stack>
                            </div>

                            <div className="mg-b-10">
                                <Stack spacing={2} direction="row">
                                    <Button variant="outlined" className="contained-end-btn" onClick={isFromMobile ? handelScheduleForLater : scheduleForLater} disabled={isDisabled}>
                                        {isDisabled ? <CircularProgress className='spin-css' /> :
                                            <span className="join-btn-css color-white" >Schedule</span>
                                        }
                                    </Button>
                                </Stack>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default ScheduleJobModal
