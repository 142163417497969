import React from "react";
import { useUser } from "context/useContext";
import { VIP_VALUES } from "../../constants";

const OfferSubscription = ({ cost, handleLinkClick }) => {
  return (
    <span style={{
      fontSize: "14px",
    }}>

      Business Plan Cost: {" "}
      {" "}
      <span style={{
        color: "#01d4d5",
        fontWeight: "bold",
        fontSize: "14px",
      }}>
        ${(cost * 0.5).toFixed(2)}
      </span>
      <br />
      {" "}
      <a onClick={handleLinkClick} className="vip-offer-link">
        Join any Business Plan & Save 50% off this job
      </a>
    </span>
  );
};

const NormalJobFeedback = ({ normalJobCost, isTechnicianSubmitFeedback, handleLinkClick }) => {
  const { user } = useUser();
  console.log('normalJobCost ::', normalJobCost)
  return (
    <>
      <tr>
        <th>Total Job Cost</th>
        <td>
          <span className="job-value">
            {normalJobCost && (normalJobCost.promoCodeAppied || normalJobCost.isVipJob) ? (
              <>
                <strike>
                  <span className="job-value totalAmount">
                    {" "}
                    ${normalJobCost.totalCost}
                  </span>
                </strike>
                <span className="job-value">
                  {" "}
                  $ {normalJobCost.discountedCost}
                  {isTechnicianSubmitFeedback && (
                    <> <small> (Pending Review) </small> </>
                  )}
                </span>
                {user?.businessName && user?.roles.includes("owner") && !user?.customer?.subscription &&
                  <OfferSubscription cost={normalJobCost.discountedCost} handleLinkClick={handleLinkClick} />
                }
              </>
            ) : (
              <>
                {" Current Cost: "}
                ${normalJobCost && normalJobCost.totalCost}
                {isTechnicianSubmitFeedback && (
                  <> <small> (Pending Review) </small> </>
                )}
                <br />
                {user?.businessName && user?.roles.includes("owner") && !user?.customer?.subscription &&
                  <OfferSubscription cost={normalJobCost.totalCost} handleLinkClick={handleLinkClick} />
                }
              </>
            )}
          </span>
        </td>
      </tr>
      {
        (
          user && user.customer &&
          !user.customer.vipVote.voted &&
          !user.customer.subscription && // Hide if user has a subscription
          (
            (normalJobCost && normalJobCost.promoCodeAppied)
              ? (normalJobCost.discountedCost > 0)
              : (normalJobCost.totalCost > 0)
          ) &&
          !user?.businessName
        ) ? (
          <>
            <tr>
              <th style={{
                color: "#01d4d5",
              }}> Save {VIP_VALUES.meetingOffer}%! </th>
              <td>
                <span className="job-value">
                  {normalJobCost && normalJobCost.promoCodeAppied ? (
                    <>
                      You could have paid
                      <span className="job-value offer">
                        {" "}
                        ${(normalJobCost.discountedCost - (normalJobCost.discountedCost * (VIP_VALUES.meetingOffer / 100))).toFixed(2)}
                      </span>
                    </>
                  ) : (
                    <>
                      You could have paid
                      <span className="job-value offer">
                        {" "}
                        ${(normalJobCost.totalCost - (normalJobCost.totalCost * (VIP_VALUES.meetingOffer / 100))).toFixed(2)}
                      </span>
                    </>
                  )}
                  <br />
                  {" "}
                  <a onClick={handleLinkClick} className="vip-offer-link">
                    Sign up & get 20% on your next job!
                  </a>
                </span>
              </td>
            </tr>
          </>
        ) : null
      }

    </>
  );
};

export default NormalJobFeedback;
